import React, { useEffect, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { FirebaseContext } from "../../context/FirebaseContext";

import Preloader from "../loading/Preloader";

function ProtectedRoute({ component: Component }) {
    const { auth } = useContext(FirebaseContext);

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, [auth]);

    if (loading) return <Preloader />;

    return user ? <Component /> : <Navigate to="/sign-in" replace />;
}

export default ProtectedRoute;
