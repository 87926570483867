import React from 'react';
import Footer from '../../components/landingpage/Footer';

import useScrollToTop from '../../hooks/useScrollToTop';

function PricingPolicy() {

    useScrollToTop()

    return (
        <>
            <div className='pricing-policy misc'>
                <div className='misc-header'>
                    <div className='misc-inner-container'>
                        <h2 className=' misc-title'> Pricing</h2>
                    </div>
                    <div className='app-horizontal-line' />
                </div>

                <h2 className='misc-bold-title'> Thank you for considering NioMath. </h2>

                <div className='pricing-policy__info'>
                    <h3 className='pricing-policy__info-title'> Below is the pricing policy we offer:</h3>

                    <div className='pricing-policy__info-container'>

                        <div className='pricing-policy__info-content'>
                            <h4 className='pricing-policy__info-content__title'> Single Chapter Access </h4>

                            <div className='pricing-policy__info-content__container'>
                                <div className='pricing-policy__info-content__container-title'> Price </div>
                                <div className='pricing-policy__info-content__container-pricing strikethrough'> ₹250 </div>

                                <div className='pricing-policy__info-content__container-title'> Discount </div>
                                <div className='pricing-policy__info-content__container-pricing'> 60% Seasonal Discount </div>
                            </div>

                            <div className='pricing-policy__info-content_horizontal-line' />

                            <div className='pricing-policy__info-content__pricing'> Final Price: ₹99 </div>

                        </div>


                        <div className='pricing-policy__info-content'>
                            <h4 className='pricing-policy__info-content__title'> Complete 24-Chapter Access </h4>

                            <div className='pricing-policy__info-content__container'>
                                <div className='pricing-policy__info-content__container-title'> Price </div>
                                <div className='pricing-policy__info-content__container-pricing strikethrough'> ₹6000 </div>

                                <div className='pricing-policy__info-content__container-title'> Discount </div>
                                <div className='pricing-policy__info-content__container-pricing'> 80% Seasonal Discount </div>
                            </div>

                            <div className='pricing-policy__info-content_horizontal-line' />

                            <div className='pricing-policy__info-content__pricing'> Final Price:  ₹899 </div>
                        </div>

                    </div>

                    <h2 className='misc-sub-title underline'>
                        Please note the following terms and conditions:
                    </h2>

                    <div className='misc-paragraph-container'>
                        <h3 className='misc__paragraph-title'> Discounts </h3>
                        <p className='misc__paragraph'> The mentioned discounts are applicable only as per the specified conditions.Discounts cannot be combined or stacked with any other offers or promotions. </p>
                    </div>

                    <div className='misc-paragraph-container'>
                        <h3 className='misc__paragraph-title'> Payment </h3>
                        <p className='misc__paragraph'>  Refunds or cancellations are not available for single-chapter accesses or complete 24-chapter accesses once the payment is processed.In case of technical issues or errors on our part, please contact our customer support for assistance. </p>
                    </div>

                    <div className='misc-paragraph-container'>
                        <h3 className='misc__paragraph-title'> Access Period: </h3>
                        <p className='misc__paragraph'> Single Chapter Access: Once purchased, you will have unlimited access to the specific chapter.
                            Complete 24-Chapter Access: Once purchased, you will have unlimited access to all 24 chapters. </p>
                    </div>

                    <div className='misc-paragraph-container'>
                        <h3 className='misc__paragraph-title'> Account Sharing</h3>
                        <p className='misc__paragraph'> Sharing of accounts or content with others is strictly prohibited and may result in account suspension or termination. </p>
                    </div>

                    <div className='misc-paragraph-container'>
                        <h3 className='misc__paragraph-title'> Pricing Changes</h3>
                        <p className='misc__paragraph'> We reserve the right to modify the pricing policy at any time. However, any purchases made before the change will be honored as per the terms at the time of purchase.</p>
                    </div>

                    <div className='misc-paragraph-container'>
                        <h3 className='misc__paragraph-title'> Availability </h3>
                        <p className='misc__paragraph'> The availability of specific chapters may be subject to change or updates. We strive to provide uninterrupted access, but there may be instances where certain chapters are temporarily unavailable due to maintenance or other reasons. </p>
                    </div>

                    <p className='misc-footnote'> Please review our pricing policy carefully before making any purchases. Should you have any further questions or require assistance, please don't hesitate to contact our customer support.</p>
                </div>

            </div>
            < Footer />

        </>
    )
}

export default PricingPolicy