import React, { useRef, useEffect, useState } from "react";
import OverLayLoader from "../../components/ui/loading/OverLayLoader";

import "./contact-us.css";

import {
    phoneIconURL, whatsappIcon,
    locationIcon, messageTextIcon,
    inputErrorIcon, simpleTickIcon
} from "../../assets/icons";

const ContactUs = () => {

    const footerRef = useRef()

    const [footerHeight, setfooterHeight] = useState(100)

    const [isMsgSent, setIsMsgSent] = useState(false)
    const [nameValue, setNameValue] = useState('')
    const [mobileNumberValue, setMobileNumberValue] = useState('')
    const [messageValue, setMessageValue] = useState('')
    const [loading, setLoading] = useState(false)

    const [errorWhich, setErrorWhich] = useState([])
    const [errorText, setErrorText] = useState('')

    const removeError = (element) => {
        const indexToRemove = errorWhich.indexOf(element);
        if (indexToRemove !== -1) {
            errorWhich.splice(indexToRemove, 1);
        }
    }

    const handleNameInput = (e) => {
        setIsMsgSent(false)
        removeError('name-input')

        const val = e.target.value;
        if (/\d/.test(val)) return;
        const capitalizedVal = val.charAt(0).toUpperCase() + val.slice(1);
        setNameValue(capitalizedVal)
    }

    const handlePhoneInput = (e) => {
        setIsMsgSent(false)
        removeError('phone-input')

        const val = e.target.value;
        if (isNaN(+val)) return
        setMobileNumberValue(val)
    }

    const handleMessageInput = (e) => {
        setIsMsgSent(false)
        removeError('msg-input')

        const val = e.target.value;
        setMessageValue(val)
    }

    const checkFieldValidity = () => {

        if (!nameValue) {
            setErrorWhich([...new Set([...errorWhich, 'name-input'])])
            setErrorText("Name shouldn't be empty.")
            return false
        }

        if (nameValue.length < 2) {
            setErrorWhich([...new Set([...errorWhich, 'name-input'])])
            setErrorText("Name should be at least 2 characters.")
            return false
        }

        if (!mobileNumberValue) {
            setErrorWhich([...new Set([...errorWhich, 'phone-input'])])
            setErrorText("Phone number shouldn't be empty.")
            return false
        }

        if (mobileNumberValue.length < 10) {
            setErrorWhich([...new Set([...errorWhich, 'phone-input'])])
            setErrorText("Phone number should be 10 digits.")
            return false
        }

        return true;
    }

    const clearFields = () => {
        setNameValue("")
        setMobileNumberValue("")
        setMessageValue("")
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(nameValue, mobileNumberValue, messageValue)

        if (checkFieldValidity()) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                clearFields()
                setIsMsgSent(true)
            }, 1500)
        }
    }

    useEffect(() => {
        if (footerRef.current) {
            const dimensions = footerRef.current.getBoundingClientRect();
            setfooterHeight(dimensions.height)
        }

    }, [footerRef.current]);

    return (
        <div className="contact-us" >

            {loading && <OverLayLoader />}

            <div className="contact-us-form-container" style={{ minHeight: `calc(100vh - ${footerHeight + 116}px)` }}>
                <h2 className="contact-us__title">  Having troubles? Contact us now!</h2>

                <form className="contact-us__form" onSubmit={handleSubmit}>

                    <label htmlFor="name-input" className="contact-us__form__item">
                        <span className="contact-us__form__label"> Name </span>

                        <div className={`contact-us__form__input 
                        ${errorWhich.includes('name-input') ? "error" : ""}`}>

                            <input id="name-input" type="text"
                                value={nameValue}
                                placeholder="Enter your name!"
                                onChange={handleNameInput} />

                            {errorWhich.includes('name-input')
                                && <img src={inputErrorIcon} className='contact-form-error-icon' />}
                        </div>

                        <div className='contact-us__form__error-text name-input'>
                            {errorWhich.includes('name-input') && errorText}
                        </div>

                    </label>

                    <label htmlFor="mobile-number-input" className="contact-us__form__item">
                        <span className="contact-us__form__label"> Mobile Number </span>

                        <div className={`contact-us__form__input 
                        ${errorWhich.includes('phone-input') ? "error" : ""}`}>
                            <input id="mobile-number-input"
                                placeholder="Enter your mobile number"
                                value={mobileNumberValue}
                                onChange={handlePhoneInput} />

                            {errorWhich.includes('phone-input')
                                && <img src={inputErrorIcon} className='contact-form-error-icon' />}

                        </div>

                        <div className='contact-us__form__error-text name-input'>
                            {errorWhich.includes('phone-input') && errorText}
                        </div>

                    </label>

                    <label htmlFor="textarea-input" className="contact-us__form__item">
                        <span className="contact-us__form__label"> Write us a message </span>

                        <div className={`contact-us__form__input 
                        ${errorWhich.includes('msg-input') ? "error" : ""}`}>
                            <textarea id="textarea-input"
                                value={messageValue}
                                placeholder="Tell us something about your query! (Optional)"
                                onChange={handleMessageInput} />

                            {errorWhich.includes('msg-input')
                                && <img src={inputErrorIcon} className='contact-form-error-icon' />}
                        </div>

                        <div className='contact-us__form__error-text name-input'>
                            {errorWhich.includes('msg-input') && errorText}
                        </div>
                    </label>

                    <button className="app-btn-1 contact-us__sub-btn" >
                        {!isMsgSent
                            ? "Submit Details"
                            : <img src={simpleTickIcon} className='contact-us-tick-icon' />}
                    </button>

                </form>
            </div>

            <div className="contact-us__footer" ref={footerRef}>
                <div className="contact-us__footer-container">
                    <h3 className="ceo-info">
                        <span className="app-bold">  Manav Kothari</span>
                        <span>  (CEO)</span>
                    </h3>

                    <div className="contact-info">
                        <a href="tel:+1234567890">
                            <img src={phoneIconURL} className="contact-us__icon" />
                            <h3>  +919650052472 </h3>
                        </a>
                        <a href="mailto:m@nioclass.com">
                            <img src={messageTextIcon} className="contact-us__icon" />
                            <h3> m@nioclass.com</h3>
                        </a>
                        <a href="https://wa.me/+919650052472"
                            target="_blank" rel="noreferrer">
                            <img src={whatsappIcon} className="contact-us__icon" />
                            <h3>  +919650052472 </h3>
                        </a>
                        <a href="https://goo.gl/maps/NxmJ7PTyhdqQGkk29"
                            target="_blank" rel="noreferrer">
                            <img src={locationIcon} className="contact-us__icon" />
                            <h3> J 202 Saket New Delhi </h3>
                        </a>

                    </div>
                </div>
            </div>
        </div>

    );
};

export default ContactUs;