import React from 'react';
import BlogParagraph from './BlogParagraph';
import BlogNumberList from './BlogNumberList';
import BlogBulletList from './BlogBulletList';
import BlogQuote from './BlogQuote';

import './blog-body.css';

const paragraph = "Expert problem-solvers in mathematics possess a remarkable ability to swiftly approach and solve complex problems. Have you ever wondered how they do it? In this blog post, we will uncover the secrets behind their speed and efficiency, and provide you with a resource to enhance your own problem-solving skills."

const paragraph2 = "By combining these three elements—making connections, developing range, and quick recall—expert problem-solvers can devise and execute optimal solutions rapidly."

const paragraph3 = "Don't hesitate to give NioPractice a try today and witness the remarkable improvement in your mathematical problem-solving speed. Start building your expertise and conquer any challenge that comes your way."

const quote = "If you're looking to enhance your speed in mathematical problem-solving, we recommend exploring the NioPractice website. Here's how it can help you:"

const numberList = [
	{
		title: "Making Connections",
		text: 'One key factor in building speed is recognizing that no problem is entirely new. Seasoned problem-solvers instantly connect new problems to similar ones they have encountered before. By drawing upon their past experiences, they can quickly identify relevant concepts and strategies to apply to the current problem at hand..'
	},
	{
		title: "Developing Range.",
		text: "Another essential aspect of speed in mathematics is having a wide range of techniques and strategies at your disposal. Expert problem-solvers have a diverse toolkit that allows them to approach problems from different angles. By expanding your repertoire of problem-solving methods, you'll be better equipped to tackle a variety of mathematical challenges with ease and efficiency."
	},
	{
		title: "Quick Recall",
		text: "The ability to recall the right techniques and strategies swiftly from memory is crucial for speed in mathematics. Expert problem-solvers don't waste time pondering which method to use; they retrieve the appropriate approach almost instantaneously. This streamlined 'buffer time' enables them to focus on solving the problem promptly and accurately."
	}
]

const bulletList = [
	{
		title: "Build Range",
		text: 'Niopractice offers a vast collection of practice questions that allow you to quickly expose yourself to various problem types. During your practice sessions, focus solely on understanding the approach rather than performing lengthy calculations. This approach-centric learning will help expand your problem-solving range efficiently.'
	},
	{
		title: "Improve Recall Speed",
		text: "With a customized timer feature, NioPractice helps you enhance your recall speed. By setting time constraints for each question, you'll train yourself to retrieve techniques and strategies rapidly, reinforcing your ability to solve problems swiftly and accurately."
	},
	{
		title: "AI-Assisted Memory Tracking",
		text: "NioPractice's AI system tracks your memory formation and curates revision. As you continue using the platform, you'll witness continuous improvement in your chosen chapters. The more you practice, the faster you'll become in mastering the math concepts of your choice."
	}
]

const BlogBody = ({ post }) => {

	return (
		<div className="blog-body">
			<BlogParagraph text={paragraph} />
			<BlogNumberList blogList={numberList} />
			<BlogParagraph text={paragraph2} />
			<BlogQuote text={quote} />
			<BlogBulletList blogList={bulletList} />
			<BlogParagraph text={paragraph3} />
		</div>
	)
}

export default BlogBody



