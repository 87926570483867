



const hambergerMenuIconURL = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/hambergermenu.svg';

const nioLogo = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/nioLogo.svg"
const dbImg = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/dbimg.png"
const dbImg2 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/myDashboard2.png"
const img1 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img1.png"
const img2 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img2.png"
const img3 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img3.png"
const img4 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img4.png"

const b1Mob = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioMath_B1_Mob.gif";
const b1Web = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioMath_B1_Web.gif";
const b2Mob = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioMath_B2_Mob.gif";
const b2Web = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioMath_B2_Web.gif";
const b3Mob = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioMath_B3_Mob.gif";
const b3Web = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioMath_B3_Web.gif";

const manavProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem1.png"
const himanshuProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem2.png"
const kunalProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem3.png"
const alvinProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem4.png"

const serviceImg1 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/service-section-img-1.svg"
const serviceImg2 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/service-section-img-2.svg"
const desktopMobileNioImg = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/desktop-mobile-nio-img.svg"

export {
    hambergerMenuIconURL, nioLogo,
    dbImg, dbImg2, img1, img2, img3, img4,
    b1Mob, b1Web, b2Mob, b2Web, b3Mob, b3Web,
    manavProfile, himanshuProfile, kunalProfile, alvinProfile,

    serviceImg1, serviceImg2, desktopMobileNioImg
}