const blogData = [
    {
        id: 'b1',
        imgURL: 'https://images.pexels.com/photos/3807755/pexels-photo-3807755.jpeg?auto=compress&cs=tinysrgb&w=1600',
        blogTitle: "Speed focused learning algorithm. Optimising student learning.",
        blogArticle: "Crack tough questions in < 2 minutes. Our software makes you think and solve faster. Crack tough questions in < 2 minutes. Our software makes you think and solve faster. Crack tough questions in < 2 minutes. Our software makes you think and solve faster.Crack tough questions in < 2 minutes. Our software makes you think and solve faster and better. Our software makes you think and solve faster.",
        publishedDate: '19th May',
        readTime: '8 mins',
        tags: ['maths'],
        stats: {
            comments: 0,
            likes: 0,
            shares: 0,
            saves: 0
        }
    },
    {
        id: 'b2',
        imgURL: 'https://images.pexels.com/photos/3768126/pexels-photo-3768126.jpeg?auto=compress&cs=tinysrgb&w=1600',
        blogTitle: "Speed focused learning algorithm. Optimising student learning.",
        blogArticle: "Crack tough questions in < 2 minutes. Our software makes you think and solve faster. Crack tough questions in < 2 minutes. Our software makes you think and solve faster. Crack tough questions in < 2 minutes. Our software makes you think and solve faster.",
        publishedDate: '19th May',
        readTime: '7 mins',
        tags: ['maths'],
        stats: {
            comments: 0,
            likes: 0,
            shares: 0,
            saves: 0
        }
    },
    {
        id: 'b3',
        imgURL: 'https://images.pexels.com/photos/3380743/pexels-photo-3380743.jpeg?auto=compress&cs=tinysrgb&w=1600',
        blogTitle: "Speed focused learning algorithm. Optimising student learning.",
        blogArticle: "Crack tough questions in < 2 minutes. Our software makes you think and solve faster. Crack tough questions in < 2 minutes. Our software makes you think and solve faster. Crack tough questions in < 2 minutes. Our software makes you think and solve faster.Crack tough questions in < 2 minutes. Our software makes you think and solve faster and better. Our software makes you think and solve faster.",
        publishedDate: '19th May',
        readTime: '9 mins',
        tags: ['maths', 'JEE'],
        stats: {
            comments: 0,
            likes: 0,
            shares: 0,
            saves: 0
        }
    },
]

export default blogData;