import { useState, useEffect, createContext } from "react";
import { getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import useCurrentUser from "../redux/control/useCurrentUser";

const firebaseConfig = {
    // // my key
    // apiKey: "AIzaSyByhAOE_4QBgjY1tnTbcCK6LO_UDmV3jeQ",
    // authDomain: "fir-auth-c2f1d.firebaseapp.com",
    // projectId: "fir-auth-c2f1d",
    // storageBucket: "fir-auth-c2f1d.appspot.com",
    // messagingSenderId: "146373223277",
    // appId: "1:146373223277:web:b77c2537d8671953913064",
    // measurementId: "G-ZQZRDSYNK4",
    // // my key

    //  your key
    apiKey: "AIzaSyC5NLmGt0_-jl9c5v8iYlQb55NJdUYUNp8",
    authDomain: "nioclass-4073f.firebaseapp.com",
    projectId: "nioclass-4073f",
    storageBucket: "nioclass-4073f.appspot.com",
    messagingSenderId: "378679538824",
    appId: "1:378679538824:web:2fc9481d98afe484629b3d",
    measurementId: "G-VBDVQQVPNQ",
    // ---minmain
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
    // appId:  process.env.REACT_APP_APP_ID,
    // measurementId:  process.env.REACT_APP_MEASUREMENT_ID,

    //  your key
    // AIzaSyC5NLmGt0_-jl9c5v8iYlQb55NJdUYUNp8

    // apiKey: "AIzaSyC5w7EqUryLOibFe3haofb5VQ8fvcCRL-I",
    // authDomain: "project-807036436705.firebaseapp.com",
    // projectId: "project-807036436705",
    // storageBucket: "project-807036436705.appspot.com",
    // messagingSenderId: "807036436705",
    // appId: "1:807036436705:android:5d34aa3ac9ee83689077a0",
    // measurementId: "G-VBDVQQVPNQ",
};

export const FirebaseContext = createContext();

const app = initializeApp(firebaseConfig);
export const authUser = getAuth(app);

const FirebaseContextProvider = ({ children }) => {

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);

    // const [auth, setAuth] = useState()

    // useEffect(() => {
    //     setAuth()
    // }, [authFb])

    return (
        <FirebaseContext.Provider value={{ app, auth, db }}>
            {children}
        </FirebaseContext.Provider>
    );
};
export default FirebaseContextProvider;
