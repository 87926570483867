import React, { useState, useEffect, useRef } from 'react';

import { searchIcon, searchIconFill } from '../../assets/icons';

import './search.css';

function SearchBox(props) {

    const { filterChapters, flipDirection } = props;

    const [isClicked, setIsClicked] = useState(true)
    const [searchFieldValue, setSearchFieldValue] = useState('')

    const inputFieldRef = useRef()

    useEffect(() => {
        if (inputFieldRef.current && window.innerWidth > 340) {
            inputFieldRef.current.focus()
        }
    }, [inputFieldRef.current, isClicked])

    const handleBtnClick = () => {
        setIsClicked(!isClicked)
    }

    const handleSearchField = (e) => {
        const val = e.target.value;
        setSearchFieldValue(val)
        filterChapters(val)
    }


    return (
        <div className={`searchBox ${isClicked ? 'clicked' : ''} ${flipDirection ? 'flip-direction' : ''}`}>
            <button className='searchBtn'
                onClick={handleBtnClick}
            >
                <img
                    src={isClicked ? searchIconFill : searchIcon}
                    alt="nio class search"
                    className="searchIcon"
                />
            </button>
            {
                isClicked
                && <input
                    ref={inputFieldRef}
                    onChange={handleSearchField}
                    value={searchFieldValue}
                    placeholder='Search Chapters'
                    className='searchField'
                />
            }

        </div>
    )
}

export default SearchBox;