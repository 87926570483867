import { useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from '../../context/FirebaseContext'

import SimpleNavLinks from "./SimpleNavLinks";
import AuthenticatedNavLinks from "./AuthenticatedNavLinks";

import "./styles/navbar.css";

function Navbar({ user, setUser, loaded, setLoaded }) {

    const navigate = useNavigate();
    const { auth } = useContext(FirebaseContext);

    const handleClickLogo = () => {
        navigate("/");
    };

    const handleStartPracticingBtn = () => {
        navigate("/chapter-selection");
    }

    useEffect(() => {
        auth.onAuthStateChanged(function (user) {
            if (user) setUser(user);
            else setUser(null);
        })
        setLoaded(true);
    }, [auth]);

    return (
        <header className="header">

            <SimpleNavLinks
                handleClickLogo={handleClickLogo}
                handleStartPracticingBtn={handleStartPracticingBtn} />

        </header>
    );
}

export default Navbar;
