import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';

import blogData from "./data/blogData";
import BlogBody from "./BlogBody";
import BlogWidget from "./BlogWidget";
import BlogTags from "./BlogTags";
import BlogStats from "./BlogStats";

import Footer from '../../components/landingpage/Footer';

import './blog-view.css';

const Blog = () => {
	const param = useParams();

	const [post, setPost] = useState({});

	const blogViewRef = useRef();

	const scrollToTop = () => {
		scroll.scrollToTop();
	}

	const settingPost = () => {
		blogData.forEach((blog) => {
			if (blog.id === param.blogId) setPost(blog);
		});
	};

	useEffect(() => {
		scrollToTop()
		settingPost()
	}, [param])

	return (
		<div ref={blogViewRef} className="blog-view">
			<h1 className="blog-view__heading">Blogs</h1>
			<div className="blog-view__body-container">
				<div className="blog-view__main-container">
					{/* <BlogInfo post={post} /> */}
					<img
						className="blog-info__img"
						src={post?.imgURL}
						alt={post?.blogTitle}
					/>

					<div className="blog-info__stick">
						<h2 className="blog-info__title">{post?.blogTitle} </h2>
						<div className="blog-info__details">
							<BlogTags />
							<BlogStats stats={post.stats} />
						</div>
					</div>
					<BlogBody post={post} />
				</div>
				<aside className="blog-view__widget-container">
					<BlogWidget posts={blogData} />
				</aside>
			</div>
			<Footer />
		</div>
	);
};

export default Blog;
