import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import TestimonialCarousel from "./TestimonialCarousel";
// import StudentCommentsWithSwiper from "./StudentCommentMobile";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import "./swiper.css";
import './testimonials.css';

const comments = [

    {
        id: "1",
        image: "Harshit_Verma.svg",
        name: "Harshit Verma",
        // class: "Class 12",
        comment: "Covered all practice problems in less than two days.",
    },
    {
        id: "2",
        image: "Yash_Sharma.svg",
        name: "Yash Sharma",
        // class: "Class 11",
        comment: "I was able to complete all 18 questions within 1 hr time limit."
    },
    {
        id: "3",
        image: "Vats_Agarwal.svg",
        name: "Vats Agarwal",
        // class: "Class 10",
        comment: "Tough problems seem way easier now.",
    },
];


export function TestimonialCards({ data }) {

    return (
        <div className="testimonial__card">
            <div className="testimonial__card__comment">
                <span className='app-italic'>" </span>
                {data.comment}
                <span className='app-italic'>" </span>  </div>
            <img className="testimonial__card__pic"
                src={data.image}
                alt={data.name}
            />
            <div className="testimonial__card__name">{data.name}</div>
        </div>
    )
}

function Testimonials() {

    const breakpoints = {
        600: {
            slidesPerView: 1,
        },

        800: {
            slidesPerView: 2,
        },

        1024: {
            slidesPerView: 3,
        },
    };

    return (

        <div className='testimonials'>
            <h2 className="tesitmonials__title">What students have to say</h2>
            <div className="tesitmonials__swiper">
                <Swiper              // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={20}
                    // navigation
                    loop={1}
                    pagination={{ clickable: true }}
                    breakpoints={breakpoints}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
                >
                    {comments.map((data) => <SwiperSlide key={data.id} >
                        <TestimonialCards data={data} />
                    </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>

    )
}

export default Testimonials