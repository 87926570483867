import React from 'react';

import { serviceImg1, serviceImg2 } from '../../assets/images';

import "./service-section.css";

function ServiceSection() {
    return (
        <div className='service-section'>
            <h2 className='service-section_title'>What we offer?</h2>
            <div className='service-section__inner 1'>
                <h2 className='service-section__inner__title'> Seamless Integration</h2>
                <p className='service-section__inner__sub-title'>NioMath integrates seamlessly with your existing Web and Mobile apps, as well as your Curriculum. Alternatively, you can use Niomath’s digital infrastructure and curriculum to power your organization.</p>
                <img src={serviceImg1} className='service-section__inner__img1' />
            </div>

            <div className='service-section__inner 2'>
                <h2 className='service-section__inner__title'> Your own dashboard</h2>
                <p className='service-section__inner__sub-title'>NioMath integrates seamlessly with your existing Web and Mobile apps, as well as your Curriculum. Alternatively, you can use Niomath’s digital infrastructure and curriculum to power your organization.</p>
                <img src={serviceImg2} className='service-section__inner__img2' />
            </div>
        </div>
    )
}

export default ServiceSection;