import React from 'react';
import { useNavigate } from 'react-router-dom';

import './blog-article-card.css'

function BlogArticleCard({ blog }) {
    const navigate = useNavigate()

    const handleNavigateToBlogView = (blogId) => {
        navigate(`/blog/${blogId}`);
    };

    return (
        <div className='article-card'>
            <div className='article-card__img-container'
                onClick={() => handleNavigateToBlogView(blog.id)}
            >
                <img src={blog.imgURL} className='article-card__img' />
            </div>
            <h1 className='article-card__title'> {blog.blogTitle}</h1>
            <div className='article-card__body'>
                <p className='article-card__sub-title'>  {blog.blogArticle}</p>
                <div className='article-card__button-info'>
                    <span className='article-card__date'> {blog.publishedDate}</span>
                    <span className='article-card__seperator-dot'> </span>
                    <span className='article-card__read-time'>{blog.readTime}</span>
                </div>
            </div>
        </div>
    )
}

export default BlogArticleCard;