import './back-drop.css'

function BackDrop(props) {
    const { closeHandler, color = "",
        blur, topBlur, midBlur, bottomBlur,
        noHeader,
        midHeight = "25%" } = props;

	const handleKeyDown = () =>{}

    return (
        <div className={`backdrop ${color} ${noHeader ? 'no-header' : ''} ${blur ? 'bg-blur' : ''}`}
            id="backdrop"
            role="button"
            tabIndex={0}
            aria-label="backdrop"
            onKeyDown={handleKeyDown}
            onClick={closeHandler}>

            <div className={`backdrop_section header-section ${blur ? 'bg-blur' : ''}`} />
            <div className={`backdrop_section top-section ${topBlur || blur ? 'bg-blur' : ''}`} />
            <div style={{ height: midHeight }} className={`backdrop_section mid-section ${midBlur || blur ? 'bg-blur' : ''}`} />
            <div className={`backdrop_section bottom-section ${bottomBlur || blur ? 'bg-blur' : ''}`} />
        </div>
    );
}

export default BackDrop;
