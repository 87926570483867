import React from 'react';
import xmlbuilder from 'xmlbuilder';

const Sitemap = () => {
  const sitemapData = [
    { url: 'https://www.niomath.com/', lastmod: "2023-05-30", changefreq: "daily", priority: 1 },
    { url: 'https://www.niomath.com/chapter-selection', lastmod: "2023-05-30", changefreq: "monthly", priority: 0.3 },
    { url: 'https://www.niomath.com/about-us', lastmod: "2023-05-30", changefreq: "daily", priority: 0.3 },
    { url: 'https://www.niomath.com/blog', lastmod: "2023-05-30", changefreq: "daily", priority: 0.5 },
    { url: 'https://www.niomath.com/contact-us', lastmod: "2023-05-30", changefreq: "monthly", priority: 0.3 },
    { url: 'https://www.niomath.com/sign-in', lastmod: "2023-05-30", changefreq: "daily", priority: 0.9 },
    { url: 'https://www.niomath.com/Terms-and-Conditions', lastmod: "2023-05-30", changefreq: "monthly", priority: 0.4 },
    { url: 'https://www.niomath.com/Pricing-Policy', lastmod: "2023-05-30", changefreq: "monthly", priority: 0.4 },
    { url: 'https://www.niomath.com/Cancellation-And-Refund-Policy', lastmod: "2023-05-30", changefreq: "monthly", priority: 0.3 },
    { url: 'https://www.niomath.com/privacy-policy', lastmod: "2023-05-30", changefreq: "monthly", priority: 0.3 },
    { url: 'https://www.niomath.com/sitemap.xml', lastmod: "2023-05-30", changefreq: "weekly", priority: 0.5 },
    { url: 'https://www.niomath.com/our-team', lastmod: "2023-05-30", changefreq: "monthly", priority: 0.3 },
    // { url: 'https://www.niomath.com/tutorial' ,lastmod: "2023-05-30", changefreq: "monthly", priority:0.3},
    { url: 'https://www.niomath.com/faq', lastmod: "2023-05-30", changefreq: "weekly", priority: 0.3 },

    // Add more URLs as needed
  ];
  ////console.log(sitemapData)

  const xml = xmlbuilder.create('urlset', { version: '1.0', encoding: 'UTF-8' })
    .att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9').att("xmlns:xhtml", "http://www.w3.org/1999/xhtml");

  sitemapData.forEach((item) => {
    xml.ele('url').ele({ 'loc': item.url, "lastmod": item.lastmod, "changefreq": item.changefreq, "priority": item.priority });
  });

  const sitemapXml = xml.end({ pretty: true });

  ////console.log('sitemapXml:', sitemapXml);

  return <pre style={{ color: "white", marginTop: "6em" }}>{sitemapXml}</pre>;
};

export default Sitemap;
