import React from 'react';

import { desktopMobileNioImg } from '../../assets/images';

function SectionFive({ handleClickStartPracticising }) {

    return (
        <div className="sectionfive">
            <div className='sectionfive__outer-container'>
                <div className='sectionfive__container'>

                    <div className='sectionfive__img1-container'>
                        <img src={desktopMobileNioImg}
                            className="sectionfive__img1"
                            alt="landing-img-5"
                        />
                    </div>

                    <div className='sectionfive__inner-container'>

                        <h2 className="sectionfive__title"> Available across all platforms! </h2>

                        {/* <a href="https://forms.gle/mfG6owfoYx31jXgn9" target='_'>
                            <button

                                className="app-btn-1 sectionfive__btn"
                            >
                                GET ACCESS!
                            </button>
                        </a> */}

                        <div>
                            <p className="app-faded-clr">
                                Mobile Application is coming soon to help you
                            </p>
                            <p className="">
                                <b>Study at your convenience!
                                </b>
                            </p>
                        </div>
                    </div>
                    {/* 
                    <img
                        className="sectionfive__img2"
                        src="playStoreAppStore.png"
                        alt="landing-img-5"
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default SectionFive