import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './blog-widget.css';

const BlogWidget = ({ posts }) => {
	const navigate = useNavigate()

	const [relatedPosts, setRelatedPosts] = useState([]);

	const handleNavigateToBlogView = (blogId) => {
		navigate(`/blog/${blogId}`);
	};

	useEffect(() => {
		setRelatedPosts([...posts])
	}, [])

	return (
		<div className="blog-widget">
			<div className="blog-widget__container">
				<h3 className="blog-widget__heading">
					Related Blogs
				</h3>
				{relatedPosts.map((post, i) => (
					<div key={i} className="blog-widget__contents"
						onClick={() => handleNavigateToBlogView(post.id)}
					>
						<div className="blog-widget__img-container">
							<img
								alt={post?.blogTitle}
								src={post?.imgURL}
								className="blog-widget__img"
							/>
						</div>
						<div className="blog-widget__text">
							<div className="blog-widget__text-title">
								{post?.blogTitle.split(' ').slice(0, 5).join(' ')}...
							</div>
							<div className="blog-widget__text-content">
								{post?.blogArticle.split(' ').slice(0, 15).join(' ')}
								...
							</div>
							<div className='blog-widget__text-time'>
								<span className='blog-widget__date'> {post?.publishedDate}</span>
								<span className='blog-widget__seperator-dot'> </span>
								<span className='blog-widget__read-time'>{post?.readTime}</span>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default BlogWidget
