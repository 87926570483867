import React, { useState } from 'react';
import axios from 'axios';

import OverLayLoader from '../ui/loading/OverLayLoader';

import { simpleTickIcon, inputErrorIcon } from '../../assets/icons';

import "./contact-form.css";

function ContactForm() {

    const [isMsgSent, setIsMsgSent] = useState(false)

    const [nameVal, setNameVal] = useState('')
    const [phoneVal, setPhoneVal] = useState('')
    const [emailVal, setEmailVal] = useState('')

    const [errorWhich, setErrorWhich] = useState([])
    const [errorText, setErrorText] = useState('')

    const removeError = (element) => {
        const indexToRemove = errorWhich.indexOf(element);
        if (indexToRemove !== -1) {
            errorWhich.splice(indexToRemove, 1);
        }
    }

    const handleInputNameChange = (e) => {
        setIsMsgSent(false)
        removeError('name-input')

        const val = e.target.value;
        if (/\d/.test(val)) return;
        const capitalizedVal = val.charAt(0).toUpperCase() + val.slice(1);
        setNameVal(capitalizedVal)
    }

    const handleInputPhoneChange = (e) => {
        setIsMsgSent(false)
        removeError('phone-input')

        const val = e.target.value;
        if (isNaN(+val)) return
        setPhoneVal(val)
    }

    const handleInputEmailChange = (e) => {
        setIsMsgSent(false)
        removeError('email-input')

        const val = e.target.value;
        setEmailVal(val)
    }

    const scriptUrl = "https://script.google.com/a/macros/nioclass.com/s/AKfycbwTMLUBBlzQqNQP6jwaLiS4DzCkOtZVkvYFYjAkmQpVep2Yw0hr-hrYCP43BdQ3c-wv/exec"
    const [loading, setLoading] = useState(false)


    const clearFields = () => {
        setNameVal("")
        setPhoneVal("")
        setEmailVal("")
    }

    const checkFieldValidity = () => {

        if (!nameVal) {
            setErrorWhich([...new Set([...errorWhich, 'name-input'])])
            setErrorText("Name shouldn't be empty.")
            return false
        }

        if (nameVal.length < 2) {
            setErrorWhich([...new Set([...errorWhich, 'name-input'])])
            setErrorText("Name should be at least 2 characters.")
            return false
        }

        if (!phoneVal) {
            setErrorWhich([...new Set([...errorWhich, 'phone-input'])])
            setErrorText("Phone number shouldn't be empty.")
            return false
        }

        if (phoneVal.length < 10) {
            setErrorWhich([...new Set([...errorWhich, 'phone-input'])])
            setErrorText("Phone number should be 10 digits.")
            return false
        }

        if (!emailVal) {
            setErrorWhich([...new Set([...errorWhich, 'email-input'])])
            setErrorText("Email shouldn't be empty.")
            return false
        }

        if (!emailVal.includes('@')) {
            setErrorWhich([...new Set([...errorWhich, 'email-input'])])
            setErrorText("Email is not valid.")
            return false
        }

        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            Name: nameVal,
            Email: emailVal,
            PhoneNumber: phoneVal
        }

        const checkValidity = checkFieldValidity(nameVal)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        if (checkValidity) {
            setLoading(true)

            axios.post(scriptUrl, data, config)
                .then(res => {
                    console.log("SUCCESSFULLY SUBMITTED");
                    setLoading(false);
                    clearFields()
                    setIsMsgSent(true)
                })
                .catch(err => console.log(err));
        }
    }

    return (
        <form className='contact-form' onSubmit={handleSubmit} >

            {loading && <OverLayLoader />}

            <h2 className='contact-form__title'>Ready to get started?</h2>
            <p className='contact-form__sub-title'>Please leave your details so our team can get back to you!</p>

            <div className='contact-form__inner-container'>

                {/* input */}
                <div className={`contact-form__item-container`}>
                    <label htmlFor="name-input" name="Name">Name</label>

                    <div className={`contact-form__item 
                    ${errorWhich.includes('name-input') ? "error" : ""} `}>
                        <input id="name-input "
                            value={nameVal}
                            onChange={handleInputNameChange}
                            placeholder='Enter you name!' />

                        {errorWhich.includes('name-input')
                            && <img src={inputErrorIcon} className='contact-form-error-icon' />}

                    </div>

                    <div className='contact-form__error-text name-input'>
                        {errorWhich.includes('name-input') && errorText}
                    </div>
                </div>

                {/* phone */}
                <div className='contact-form__item-container'>
                    <label htmlFor="phone-input">Mobile Number</label>
                    <div className={`contact-form__item 
                    ${errorWhich.includes('phone-input') ? "error" : ""} `}>
                        <input id="phone-input"
                            className={`${errorWhich.includes('phone-input') ? "error" : ""}`}
                            name="PhoneNumber" type="number" value={phoneVal}
                            placeholder="Enter your mobile number!"
                            onChange={handleInputPhoneChange} />

                        {errorWhich.includes('phone-input')
                            && <img src={inputErrorIcon} className='contact-form-error-icon' />}

                    </div>
                    <div className='contact-form__error-text name-input'>
                        {errorWhich.includes('phone-input') && errorText}
                    </div>
                </div>

                {/* email */}
                <div className='contact-form__item-container'>
                    <label htmlFor="email-input">Email Address</label>
                    <div className={`contact-form__item 
                    ${errorWhich.includes('email-input') ? "error" : ""} `}>
                        <input id="email-input" name="Email" type="text" value={emailVal}
                            className={`${errorWhich.includes('email-input') ? "error" : ""}`}
                            placeholder="Enter your email adress!"
                            onChange={handleInputEmailChange} />

                        {errorWhich.includes('email-input')
                            && <img src={inputErrorIcon} className='contact-form-error-icon' />}

                    </div>
                    <div className='contact-form__error-text name-input'>
                        {errorWhich.includes('email-input') && errorText}
                    </div>
                </div>

                <button type='submit' className='contact-form__sub-btn app-btn-1'
                >
                    {!isMsgSent
                        ? "Submit Details"
                        : <img src={simpleTickIcon} className='contact-form-tick-icon' />}
                </button>

            </div>
        </form>
    )
}

export default ContactForm;