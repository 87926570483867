import React from 'react';
import useScrollToTop from '../../hooks/useScrollToTop';

import Footer from '../../components/landingpage/Footer';
import '../misc.css';

function CancellationAndRefund() {

    useScrollToTop()

    return (
        <>
            <div className='cancellation-and-refund misc'>

                <div className='misc-header'>
                    <div className='misc-inner-container'>
                        <h1 className=' misc-title'> Cancellations and refunds</h1>
                    </div>
                    <div className='app-horizontal-line' />
                </div>

                <h2 className='misc-bold-title'>Premium Plan Cancellations and Refunds Policy of Niomath.com </h2>

                <h2 className='misc-sub-title'>
                    Please read the subscription terms and conditions carefully before subscribing to any of the subscription plans, as once you have subscribed, you cannot change or cancel your subscription plan. By subscribing and making the required payment for any online subscriptions, you acknowledge and agree to the following terms:
                </h2>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Subscription Commitment</h2>
                    <p className='misc__paragraph'> Once you subscribe and make the required payment for any online subscription plan, it shall be considered final and binding. There cannot be any changes or modifications to the subscription plan. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> No Refunds </h2>
                    <p className='misc__paragraph'> Please note that there will be no refunds for any subscription payments made. Once the payment is processed, it is non-refundable under any circumstances. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h3 className='misc__paragraph-title'> Readiness to Subscribe</h3>
                    <p className='misc__paragraph'> By subscribing to a subscription plan, you confirm that you have thoroughly reviewed the details of the plan, including its features, pricing, and any limitations or restrictions that may apply. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h3 className='misc__paragraph-title'> Termination of Subscription </h3>
                    <p className='misc__paragraph'> We reserve the right to terminate or suspend your subscription in the event of any violation of our terms and conditions or misuse of the subscription services. Such termination or suspension will be at our discretion and without any refund. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h3 className='misc__paragraph-title'> Contact Us</h3>
                    <p className='misc__paragraph'> If you have any questions or concerns regarding our subscription terms and conditions, please contact our customer support team for assistance. </p>
                </div>

                <p className='misc-footnote'>  By subscribing to any of our subscription plans, you confirm that you have read, understood, and agreed to abide by these subscription terms and conditions. </p>

                <h2 className='misc-bold-title'>Thank you for choosing Niomath.com!</h2>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> </h2>
                    <p className='misc__paragraph'>  </p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default CancellationAndRefund