import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import fbIcon from "../../assets/images/icon _facebook.svg";
import waIcon from "../../assets/images/icon _whatsapp.svg";
import ldIcon from "../../assets/images/icon _linkedin.svg";
import igIcon from "../../assets/images/icon _instagram.svg";

import "./footer.css";

const Footer = () => {
    const navigate = useNavigate()

    const [subInputValue, setSubInputValue] = useState('')

    const handleSubcribeBtn = () => {
        setSubInputValue('')
    }

    return (
        <footer className="footer">
            <div className="section-container">
                <div className="section">
                    <div>
                        <h3 className="title">Nioclass</h3>
                        <p className="subtitle">
                            Optimising student learning.
                        </p>
                    </div>

                    <div className="footer__social-media">
                        {/* <a href="https://www.facebook.com/profile.php?id=100081858384824" target="_blank" rel="noreferrer">
                            <button className="facebookLink">
                                <img src={fbIcon} alt="facebook" />
                            </button>
                        </a> */}

                        <a href="https://wa.me/+919650052472" target="_blank" rel="noreferrer">
                            <button className="WhatsAppLink">
                                <img src={waIcon} alt="whatsapp" />
                            </button>
                        </a>

                        <a href="https://www.linkedin.com/company/nioclass" target="_blank" rel="noreferrer">
                            <button className="linkedInLink">
                                <img src={ldIcon} alt="linkedIn" />
                            </button>
                        </a>

                        {/* <a href="https://www.instagram.com/niopractice/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                            <button className="instagramLink">
                                <img src={igIcon} alt="instagram" />
                            </button>
                        </a> */}
                    </div>
                </div>

                <div className="section">
                    <h3 className="title">Company</h3>
                    <div className="footer__inner-container">
                        <button className="subtitle"
                            onClick={() => navigate("/about-us")}
                        >
                            About Us
                        </button>
                        <button className="subtitle"
                            onClick={() => navigate("/blog")}>
                            Blogs
                        </button>
                        <button
                            className="subtitle"
                            onClick={() => navigate("/privacy-policy")}
                        >
                            Privacy Policy
                        </button>
                        <button
                            className="subtitle"
                            onClick={() => navigate("/terms-and-conditions")}
                        >
                            Terms and Conditions
                        </button>
                    </div>
                </div>

                <div className="section">
                    <h3 className="title">Help</h3>
                    <div className="footer__inner-container">
                        <button className="subtitle"
                            onClick={() => navigate("/sitemap")}>
                            Site Map
                        </button>
                        <button className="subtitle"
                            onClick={() => navigate("/faq")}>
                            FAQ'S
                        </button>
                        <button
                            className="subtitle"
                            onClick={() => navigate("/cancellation-and-refund-policy")}>
                            Refund Policy
                        </button>
                        <button className="subtitle"
                            onClick={() => navigate("/pricing-policy")}>
                            Pricing
                        </button>
                    </div>
                </div>

            </div>

            <div className="section newsletter">
                <h2 className="title newsletter-subscribe">  Subscribe to our Newsletter </h2>
                <div className="footer__inner-container">
                    <input
                        type="email"
                        className="footer__newsletter__input"
                        placeholder="Enter Your Email"
                        value={subInputValue}
                        onChange={(e) => setSubInputValue(e.target.value)}
                    />
                    <button className="app-btn-1 newsletter-subscribe-btn"
                        onClick={handleSubcribeBtn}
                    >Subscribe</button>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
