import React from 'react'
import { b2Mob, b2Web } from '../../assets/images'

function SectionTwo() {
    return (
        <div className="section-two" >
            <div className='section-two__img-bg app-img-border-rotate'>

                <img className='section-two__img hide-small' src={b2Web} alt="landing-img-b2" />
                <img className='section-two__img hide-big' src={b2Mob} alt="landing-img-b2" />

            </div>
            <div className="section-two__container">
                <h2 className='section-two__title'> Continuous path from easy to advanced. </h2>
                <p className="section-two__sub-title">
                    Rapidly up skill and master the toughest problems
                    with ease.
                </p>
            </div>
        </div>
    )
}

export default SectionTwo