import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import App from "./App";

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

import "./index.css";

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>
);

reportWebVitals();
