// import { fixIntForMathJax2 } from "./str2-util";

function replaceAll(string, search, replace) {
    return string?.split(search).join(replace);
}

function addDisplaylines(string) {
    let k = "\\displaylines {" + string + "}";
    return k;
}

function addDelimiterForBetterMathJax(string) {
    let k = "\\( " + string + " \\)";
    return k;
}

// let newArray = [];
// let answer = "";

// function newFunction2(value, index, array) {
//     //console.log("the value passed in is");
//     //console.log(value);

//     if (value !== null) {
//         //console.log("the new val;ue is");
//         //console.log(value.replace("sin", "\\sin"));
//         newArray[index] = value.replace("sin", "\\sin");
//     }
// }

// function regexForSin(string) {
//     //console.log("the original string is ");
//     //console.log(string);
//     newArray = [];
//     answer = "";
//     let k = string;
//     let regex_sin = /[^a-z]sin/g;
//     if (
//         Array.from(k)[0] === "s" &&
//         Array.from(k)[1] === "i" &&
//         Array.from(k)[2] === "n"
//     ) {
//         // adding a space at the beginning to help with the parsing.
//         // //console.log("Adding whitespace i n the beginning of the string")
//         k = " " + k;
//         // //console.log("the new string after adding the whitespace is")
//         // //console.log(k)
//     }
//     const myArray = k.split(regex_sin);
//     // //console.log("the values of myArray -after regex matches taken out - are")
//     // //console.log(myArray)
//     let matches = k.match(regex_sin);
//     // //console.log("the valuse of the matches array is: ")
//     // //console.log(matches)
//     if (matches !== null) {
//         matches.forEach(newFunction2);
//         let counter = 0;
//         for (var i = 0; i < myArray.length; i++) {
//             if (counter < matches.length) {
//                 answer = answer + myArray[i] + newArray[counter];
//                 counter++;
//             } else {
//                 // check if there is still an unadded value in myArray, If so, add it to the answer
//                 // and then break.
//                 if (myArray[i] !== null) {
//                     //console.log("the last value is not null");
//                     //console.log(myArray[i]);
//                     answer = answer + myArray[i];
//                 }
//                 break;
//             }
//         }
//     }
//     if (matches == null) {
//         // if there are not matches, then there means that the only
//         // response is the first value of myArray
//         if (myArray[0] !== null) {
//             answer = answer + myArray[0];
//         }
//     }
//     //console.log("the answer being returned from regexforSin");
//     //console.log(answer);
//     return answer;
// }

const span = document.createElement("span");
span.style.fontSize = "20px";
span.style.display = "flex !important";

// const canvas = document.createElement('canvas');
// const ctx = canvas.getContext('2d');
// ctx.font = '20px Arial';
// const text = 'Hello, World!';
// const textWidth = ctx.measureText(text).width;
// //console.log(`The text width is ${textWidth}px`);

// function isInText(str, index) {
//     const regex = /\\text\{([^}]*)\}/g;
//     let match;
//     while ((match = regex.exec(str)) !== null) {
//         if (index > match.index && index < regex.lastIndex) {
//             return true;
//         }
//     }
//     return false;
// }

// function processString(m) {
//     let matches = [];
//     let closing = [];
//     let flag = false;
//     //     const regex = /\\text\{([^}]*)\}/g;
//     //   let match;
//     //   while ((match = regex.exec(m)) !== null) {
//     //     if (i > match.index && i < regex.lastIndex) {
//     //       flag = true;
//     //     }
//     //     else{}
//     //   }

//     let output = "";
//     let currentLineLength = 0;
//     let lines = [];
//     const maxWidth = window.innerWidth - 85;

//     for (let i = 0; i < m.length; i++) {
//         const currentChar = m[i];

//         output += currentChar;

//         const span = document.createElement("span");
//         span.textContent = output;
//         document.body.appendChild(span);
//         currentLineLength = span.getBoundingClientRect().width;
//         document.body.removeChild(span);

//         if (currentLineLength > maxWidth && currentChar !== "}") {
//             const lastSpaceIndex = output.lastIndexOf(" ", output.length - 2);

//             const lastTextIndex = output.lastIndexOf(
//                 "\\text{",
//                 output.length - 2
//             );
//             if (lastSpaceIndex === -1 || lastTextIndex > lastSpaceIndex) {
//                 output += "}\\\\ \\text{";
//             } else {
//                 output =
//                     output.substring(0, lastSpaceIndex) +
//                     "}\\\\ \\text{" +
//                     output.substring(lastSpaceIndex + 1);
//             }

//             lines.push(output);
//             output = "";
//         }
//     }

//     // if (output) {
//     //     lines.push(output);
//     // }

//     // let temp = lines.join("");

//     // for (let i = 0; i < temp.length; i++) {
//     //     if (temp[i] === "}") temp.push(i);
//     // }

//     // while ((match = regex.exec(temp)) !== null) {
//     //     matches.push(match.index);
//     //     //console.log(match.index);
//     // }

//     // let flag = false;

//     // for (let i = 0; i < m.length; i++) {
//     //     const currentChar = m[i];
//     //     output += currentChar;
//     //     const span = document.createElement("span");
//     //     span.textContent = output;
//     //     document.body.appendChild(span);
//     //     currentLineLength = span.getBoundingClientRect().width;
//     //     document.body.removeChild(span);

//     //     for (let j = 0; j < matches.length; j++) {
//     //         if (matches[j] < i < closing[j]) {
//     //             flag = true;
//     //         }
//     //     }

//     //     if (currentLineLength > maxWidth && flag === false) {
//     //         const lastClosingIndex = output.lastIndexOf("}", output.length - 2);

//     //         m[lastClosingIndex].replace("}", "}\\\\");
//     //         // const lastTextIndex = output.lastIndexOf(
//     //         //     "\\text{",
//     //         //     output.length - 2
//     //         // );
//     //         // if (lastSpaceIndex === -1 || lastTextIndex > lastSpaceIndex) {
//     //         //     output += "}\\\\ \\text{";
//     //         // } else {
//     //         //     output =
//     //         //         output.substring(0, lastSpaceIndex) +
//     //         //         "}\\\\ \\text{" +
//     //         //         output.substring(lastSpaceIndex + 1);
//     //         // }

//     //         lines.push(output);
//     //         output = "";
//     //     }
//     // }

//     if (output) {
//         lines.push(output);
//     }
//     // lines = [];

//     // let temp = lines.join("");

//     // const lastBraceIndex = temp.lastIndexOf("}");
//     // temp[lastBraceIndex].replace("}", "}\\\\");

//     return lines.join("");
// }

export function getStringForMathJax(string) {
    // let k = string
    //   k = replaceAll(k,'\n','');
    //   k = replaceAll(k,'\r\n','');
    //   // k = replaceAll(k,"\\","\\\\");
    //   k = replaceAll(k, /\\[^()]/ ,"\\\\");
    //   k = replaceAll(k,"quad","\\quad");
    //   k = replaceAll(k,"\text","\\text");
    //   k = replaceAll(k,"\begin{aligned}","\\begin{aligned}");
    //   k = replaceAll(k,"\end{aligned}","\\end{aligned}");
    //   k = replaceAll(k, "frac","\\frac");
    //   k = replaceAll(k, "\frac","\\frac");
    //   k = replaceAll(k,"\begin{vmatrix}","$\\begin{vmatrix}");
    //   k = replaceAll(k,"\end{vmatrix}"," \\end{vmatrix}$");
    //   k = replaceAll(k,"\begin{array}","\\begin{array}");
    //   k = replaceAll(k,"\end{array}"," \\end{array}$");
    //   k = replaceAll(k,"Delta","$\\Delta$");
    //   k = replaceAll(k,"left","\\left");
    //   k = replaceAll(k,"\right","\\right");
    //   k = replaceAll(k,"sqrt","\\sqrt");
    //   k = replaceAll(k,"operatorname","\\operatorname");
    //   k = replaceAll(k,"cdot","\\cdot");
    //   k = replaceAll(k,"Rightarrow","\\Rightarrow");
    //   k = replaceAll(k,"\tan","\\tan");
    //   k = replaceAll(k,"\therefore","\\therefore");
    //   k = replaceAll(k,"ln","\\ln");
    //   k = replaceAll(k,"\times","\\times");
    //   k = replaceAll(k,"equiv","\\equiv");
    //   k = replaceAll(k,"\because","\\because");
    //   k = replaceAll(k,"\bot","\\bot");
    //   k = replaceAll(k,"pm","\\pm");
    //   k = replaceAll(k,"ldots","\\ldots");
    //   k = replaceAll(k,"\theta","\\theta");
    //   k = replaceAll(k,"left\{","left\\{");
    //   k = replaceAll(k,"right\}","right\\}");
    //   k = replaceAll(k,"log","\\log");
    //   k = replaceAll(k,/alpha/,"\\alpha");
    //   k = regexForSin(k)
    //   k = replaceAll(k,"cos","\\cos");
    //   k = replaceAll(k,"cosec","\\csc");
    //   k = replaceAll(k,"cot","\\cot");
    //   k = replaceAll(k,"sec","\\sec");
    //   // k = replaceAll(k,"left\(","left\\(");
    //   // k = replaceAll(k,"right\)","right\\)");
    //   // k = replaceAll(k,"neq","\\neq");
    //   // k = replaceAll(k,"(\\sqrt","\\(\\sqrt");
    //   // k = replaceAll(k,"\)","\\(\\sqrt");
    //   k = fixIntForMathJax2(k)
    //   k = addDisplaylines(k)
    //   k = addDelimiterForBetterMathJax(k)
    //   return k;

    let m = string;
    // //console.log("string =======>", m)
    // "\\text{This is the text that we want to make} \\\\\\text{responsive. It should render on} \\\\\\text{the new line.}"
    // if (window.innerWidth < 600) {
    //     let words = [];
    //     let currentWord = "";
    //     let inMath = false;

    //     for (let i = 0; i < m.length; i++) {
    //         let char = m[i];
    //         if (char === "$") {
    //             inMath = !inMath;
    //             if (!inMath) {
    //                 words.push("$" + currentWord + "$");
    //                 currentWord = "";
    //             }
    //         } else if (char === " " && !inMath) {
    //             words.push(currentWord);
    //             currentWord = "";
    //         } else {
    //             currentWord += char;
    //         }
    //     }

    //     if (currentWord.length > 0) {
    //         words.push(currentWord);
    //     }

    //     let tempAns = "";
    //     let currLen = 0;
    //     for (let i = 0; i < words.length; i++) {
    //         currLen += words[i].length + 1;
    //         tempAns += words[i];

    //         if (currLen > 50) {
    //             tempAns += "}\\\\";
    //             tempAns += " \\text{";
    //             currLen = 0;
    //         } else {
    //             tempAns += " ";
    //         }
    //     }

    //     m = replaceAll(tempAns, "\n", "");
    // } else {
    //     m = replaceAll(m, "\r\n", "");
    // }

    // let result = processString(m);

    // for (let i = 0; i < m.length; i++) {
    //     const currentChar = m[i];

    //     output += currentChar;

    //     span.textContent = output;
    //     document.body.appendChild(span);
    //     currentLineLength = span.getBoundingClientRect().width;
    //     document.body.removeChild(span);

    //     if (
    //         currentLineLength >= window.innerWidth - 10 &&
    //         currentChar !== "}"
    //     ) {
    //         const lastSpaceIndex = output.lastIndexOf(" ", output.length - 2);
    //         if (lastSpaceIndex === -1) {
    //             output += "}\\\\\\text{";
    //         } else {
    //             output =
    //                 output.substring(0, lastSpaceIndex) +
    //                 "}\\\\\\text{" +
    //                 output.substring(lastSpaceIndex + 1);
    //         }
    //         currentLineLength = 0;
    //     }
    // }

    // m = result;
    // const words = m.split(" ");

    // const lines = [];
    // let currentLine = "";
    // for (let i = 0; i < words.length; i++) {
    //     const word = words[i];
    //     if ((currentLine + " " + word).length > window.innerWidth - 20) {
    //         lines.push(currentLine.trim());
    //         currentLine = "";
    //     }
    //     currentLine += " " + word;
    //     //console.log(currentLine);
    //     //console.log("|||||||||||||||||||||||||||||");
    // }
    // lines.push(currentLine.trim());
    // lines.join("} \\\\ \\text{");

    m = replaceAll(m, "\r\n", "");
    m = replaceAll(m, "\n", "");
    // let n=m.split("\\")
    // m=replaceAll(m,"\n","\\")
    // //console.log("======================================================",n)
    if (m?.includes("\\begin{aligned}") && m.includes("\\end{aligned}")) {
        m = addDisplaylines(m);
        m = addDelimiterForBetterMathJax(m);
        // //console.log("hiiiiii====",m)
    } else {
        m = replaceAll(m, "$", "$$");
        // //console.log(m,"check:1=========")
    }

    return m;
}
