import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { ParticlesJS } from "./Particles";
import { useNavigate, useLocation } from "react-router-dom";

import Hero from "./Hero";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";

import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import Testimonials from "./Testimonials";
import ServiceSection from "./ServiceSection";
import ContactForm from "../contactForm/ContactForm";
import Footer from "./Footer";

// import { FirebaseContext } from "../../context/FirebaseContext";

import AOS from "aos";

// import "aos/dist/aos.css";
import "./landingpage.css";


function Landingpage() {

    const navigate = useNavigate();
    const location = useLocation();

    const contactFormRef = useRef()

    function handleClickStartPracticising() {
        navigate("/chapter-selection");
    }

    // const { auth } = useContext(FirebaseContext);

    // useEffect(() => {
    //     auth.onAuthStateChanged(function (user) {
    //         if (user) {
    //             setheaderisLogged(true);
    //         } else {
    //             setheaderisLogged(false);
    //         }
    //     });
    // }, [auth]);

    useEffect(() => {
        AOS.init({ duration: 2000, mirror: false, once: true });
    }, []);

    const [isButtonVisible, setIsButtonVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Get the vertical scroll position
            const scrollY = window.scrollY || window.pageYOffset;
            // Get the position and height of the button
            // Check if the button is visible on the screen
            if (scrollY < 312) {
                setIsButtonVisible(true);
            } else {
                setIsButtonVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isButtonVisible, setIsButtonVisible]);

    useEffect(() => {
        // console.log(location.state)

        if (contactFormRef.current && location.state?.isScrollToContactForm) {
            contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, [contactFormRef.current, location.state])

    return (
        < >
            <Helmet>
                <title> Niomath - Ai based Practice platform</title>
                <meta
                    name="Description"
                    content="Improve your JEE Maths with Niopractice! AI-powered software for quick learning and chapter mastery. Boost speed and ace your exams!"
                />
            </Helmet>

            {/* {window.innerWidth > 600 && <ParticlesJS />} */}

            <div className="landingpage" >

                <Hero handleClickStartPracticising={handleClickStartPracticising} />

                <div className="landingpage__section-seperator">
                    <h2>Why choose <span className="app-accented ">NioMath?</span></h2>
                </div>

                <SectionOne />

                {/* benefit of our app - 1 */}
                <SectionTwo />

                {/* benefit of our app - 2 */}
                <SectionThree />

                {/* benefit of our app - 2 */}
                <SectionFour />

                <ServiceSection />

                <Testimonials />

                {/* app ads */}
                <SectionFive handleClickStartPracticising={handleClickStartPracticising} />

                {/* about nioclass founder */}
                {/* <SectionSix /> */}

                <div className="contact-form" ref={contactFormRef}>
                    <ContactForm />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Landingpage;
