import siteIcon from './siteIcon.png';
import closeIcon from './close.svg';
import closeBtn from './closecircle.svg';
import menuIcon from '../images/hambergermenu.svg';
import check from './check.svg';
import checkbox from './checkbox.svg';
import checkboxFill from './checkboxFill.svg';
import checkBoxPink from './checkBoxPink.svg';
import checkFillPink from './checkFillPink.svg';
import infoIcon from './infoIcon.svg';
import errIcon from './errIcon.svg';
import swipeNextIcon from './swipeNextIcon.svg';
import successIcon from './successIcon.svg';
import copysuccess from './copysuccess.svg';

import expandBtnIcon from "./arrowdown2.svg";
import arrowUp from './arrowUp.svg'
import dropDownBtnIcon from "./arrowdown2.svg";
import closeBtnIcon from "./closecircle.svg";
import explanationBtnIcon from "./book1.svg";
import arrowInStepIcon from "./arrowright.svg";

import underScoreIcon from "./explanationUnderscore.svg";

import tickIcon from './tickIcon.svg';

import smiley1 from './smileys/smiley1.svg'
import smiley2 from './smileys/smiley2.svg'
import smiley3 from './smileys/smiley3.svg'
import smiley4 from './smileys/smiley4.svg'

import whatsappIcon from './whatsappIcon.svg';
import linkedInIcon from './LinkedInIcon.svg';

import simpleTickIcon from "./simpleTickIcon.svg"
import inputErrorIcon from "./inputErrorIcon.svg"

const phoneIconURL = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/PhoneIcon.svg';
const usersIconURL = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/UsersIcon.svg';

const searchIcon = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/search.svg';
const searchIconFill = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/search-fill.svg"

const delayTimeIconBlue = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/category-colored-box(Blue).svg'
const delayTimeIconGreen = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/category-colored-box(Green).svg'
const delayTimeIconYellow = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/category-colored-box(Yellow).svg'

const delayTimeIconSmallBlue = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/category-colored-box(-sBlue).svg'
const delayTimeIconSmallGreen = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/category-colored-box(-sGreen).svg'
const delayTimeIconSmallYellow = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/category-colored-box(-sYellow).svg'

const commentIcon = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/comment-icon.svg"
const thumbIcon = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/thumb-icon.svg"
const shareIcon = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/share-icon.svg"
const saveIcon = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/save-icon.svg"

const starIcon = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/star.svg'
const starFillIcon = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/starFill.svg"

const locationIcon = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/location.svg"
const messageTextIcon = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/messagetext.svg"

export {
    check,
    smiley1,
    smiley2,
    smiley3,
    smiley4,
    arrowUp,
    errIcon,
    tickIcon,
    siteIcon,
    closeBtn,
    menuIcon,
    checkbox,
    infoIcon,
    saveIcon,
    starIcon,
    thumbIcon,
    shareIcon,
    closeIcon,
    searchIcon,
    copysuccess,
    successIcon,
    commentIcon,
    closeBtnIcon,
    starFillIcon,
    whatsappIcon,
    linkedInIcon,
    phoneIconURL,
    usersIconURL,
    checkboxFill,
    checkBoxPink,
    expandBtnIcon,
    swipeNextIcon,
    checkFillPink,
    searchIconFill,
    dropDownBtnIcon,
    arrowInStepIcon,
    underScoreIcon,
    delayTimeIconBlue,
    delayTimeIconGreen,
    explanationBtnIcon,
    delayTimeIconYellow,
    delayTimeIconSmallBlue,
    delayTimeIconSmallGreen,
    delayTimeIconSmallYellow,
    locationIcon,
    messageTextIcon,
    simpleTickIcon,
    inputErrorIcon
}