import React from 'react';

import {
    commentIcon, thumbIcon,
    shareIcon,
    saveIcon
} from '../../assets/icons';

function BlogStats({ stats }) {

    return (
        <div className='blog-stats'>

            <div className='blog-stats__item'>
                <img className='blog-stats__icon' src={thumbIcon} />
                <h1>{stats?.likes} </h1>
            </div>

            <div className='blog-stats__item'>
                <img className='blog-stats__icon' src={commentIcon} />
                <h1>{stats?.comments} </h1>
            </div>

            <div className='blog-stats__item'>
                <img className='blog-stats__icon' src={shareIcon} />
                <h1>{stats?.shares} </h1>
            </div>

            <div className='blog-stats__item'>
                <img className='blog-stats__icon' src={saveIcon} />
                <h1>{stats?.saves} </h1>
            </div>
        </div>
    )
}

export default BlogStats