import React from 'react'
import { b3Mob, b3Web } from '../../assets/images'

function SectionThree() {
    return (
        <div className="section-three">
            <div className='section-three__img-bg app-img-border-rotate'>

                <img className="section-three__img hide-small" src={b3Web} alt="landing-img-b3" />
                <img className="section-three__img hide-big" src={b3Mob} alt="landing-img-b3" />

            </div>
            <div className="section-three__container">
                <h2 className='section-three__title'>  Accurate memory tracking. </h2>
                <p className="section-three__sub-title">
                    Remember all details. Confidently shift techniques &
                    formulas from short-term to long-term memory.
                </p>
            </div>
        </div>
    )
}

export default SectionThree