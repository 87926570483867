import React from 'react';
import Footer from '../../components/landingpage/Footer';

import useScrollToTop from '../../hooks/useScrollToTop';

import '../misc.css'

function PrivacyPolicy() {

    useScrollToTop()

    return (
        <>

            <div className='privacy-policy misc'>

                <div className='privacy-policy__header misc-header'>
                    <div className='misc-inner-container'>
                        <h1 className='privacy-policy__title  misc-title'> Privacy Policy </h1>
                        <div className='privacy-policy__last-updated-date  misc-updated-date'> Last updated: January 18, 2023 </div>
                    </div>
                    <div className='app-horizontal-line' />
                </div>

                <h2 className='privacy-policy__sub-title misc-sub-title'>
                    This Privacy Policy ("Policy") explains how [Nioclass] ("we," "us," or "our") collects, uses, and protects the personal information you provide when using the website [niomath.com] ("Website"). We are committed to ensuring the privacy and security of your personal information. By accessing or using the Website and providing your mobile number for sign-up with OTP (one-time password), you consent to the practices described in this Policy.
                </h2>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Information We Collect </h2>
                    <p className='misc__paragraph'>  A. Mobile Number: When you sign up on the Website, we collect your mobile number. </p>
                    <p className='misc__paragraph'>  B. OTP Data: We collect and process the one-time password (OTP) generated and sent to your mobile number for authentication purposes. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Use of Information </h2>
                    <p className='misc__paragraph'> A. We use the mobile number and OTP data solely for the purpose of user authentication and account management on the Website.</p>
                    <p className='misc__paragraph'> B. We do not use your mobile number or OTP data for any other purpose, such as marketing or third-party communication.</p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Security </h2>
                    <p className='misc__paragraph'>A. We implement reasonable security measures to protect your personal information, including your mobile number and OTP data, from unauthorized access, disclosure, or alteration. </p>
                    <p className='misc__paragraph'> B. We store your mobile number and OTP data in an encrypted format to maintain its confidentiality. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Third-Party Services</h2>
                    <p className='misc__paragraph'>A. We may engage third-party service providers to assist in user authentication and account management on the Website. These service providers have access to your mobile number and
                        OTP data for the sole purpose of performing their services on our behalf and are obligated to maintain the confidentiality of the information. </p>
                    <p className='misc__paragraph'>B. We do not sell, trade, or transfer your mobile number or OTP data to any third parties for marketing or other purposes. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Data Retention</h2>
                    <p className='misc__paragraph'> Data RetentionWe retain your mobile number and OTP data for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Legal Requirements</h2>
                    <p className='misc__paragraph'>We may disclose your personal information, including your mobile number and OTP data, if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency). </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Children's Privacy</h2>
                    <p className='misc__paragraph'> The Website is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with their mobile number or OTP data without your consent, please contact us, and we will promptly remove such information from our systems.</p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Changes to this Privacy Policy </h2>
                    <p className='misc__paragraph'> We reserve the right to update or modify this Policy at any time. The revised Policy will be posted on this page, and the "Effective Date" at the top will reflect the date of the latest changes. Your continued use of the Website after any modifications to the Policy constitutes your acceptance of the revised Policy. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Contact Us </h2>
                    <p className='misc__paragraph'>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at m@nioclass.com  </p>
                </div>

                <p className='misc-footnote'>  By using the Website and providing your mobile number for sign-up with OTP, you signify your acceptance of this Privacy Policy. If you do not agree to this Policy, please refrain from using the Website.
                </p>
            </div>

            <Footer />
        </>
    )
}

export default PrivacyPolicy