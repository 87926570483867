import image from "../assets/images/nioclass.png";

const teamMembers = [
    {
        id: "01",
        image: image,
        name: "Manav Kothari",
        role: "Founder",
        description:
            "tortor pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper",
    },
    {
        id: "02",
        image: image,
        name: "XYZ",
        role: "Co-Founder",
        description:
            "tortor pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper",
    },

    {
        id: "03",
        image: image,
        name: "PQR",
        role: "Software Developer",
        description:
            "tortor pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper",
    },
    {
        id: "04",
        image: image,
        name: "ABC",
        role: "Subject Expert",
        description:
            "tortor pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper",
    },
];

export default teamMembers;
