/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Helmet from "react-helmet";

import { FirebaseContext } from "../../context/FirebaseContext";
import { useNavigate } from "react-router-dom";

import useCurrentChapter from "../../redux/control/useCurrentChapter";
import useUserResponseLSRecord from "../../localStorage/useUserResponseLSRecord";

import DashboardFooter from "./DashBoardFooter";
import ChapterDropdown from "./ChapterDropdown";
import ProgressStats from "./ProgressStats";

import prepareReviewDeckAndLaunch from "./logic/prepareReviewDeckAndLaunch";
import addUserResponseToDb from "./logic/addUserResponseToDb";

import "./styles/dashboard.css";

function largestOfNumbers(a, b, c, d) {
    return Math.max.apply(null, arguments);
}

function Dashboard() {
    const { auth } = useContext(FirebaseContext);
    const { currentChapter } = useCurrentChapter();
    const navigate = useNavigate();

    const [maxNumberQuestion, setMaxNumberQuestion] = useState();

    const [greenQuestion, setgreenQuestion] = useState(7);
    const [BlueQuestion, setBlueQuestion] = useState(7);
    const [YellowQuestion, setyellowNumberQuestion] = useState(7);
    const [RedQuestion, setRedQuestion] = useState(7);

    const [htofa, setHtofA] = useState(0);
    const [htofb, setHtofB] = useState(0);
    const [htofc, setHtofC] = useState(0);
    const [htofd, setHtofD] = useState(0);

    const [chapterwisetime, setChapterwisetime] = useState({});
    const [numuniq, setNumUniq] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [valueLoaded, setValueLoaded] = useState(false);

    const [allChaptersPremium, setAllChaptersPremium] = useState(null);
    const [allChaptersPremiumLoaded, setAllChaptersPremiumLoaded] = useState(false);

    const [allChaptersMeta, setAllChaptersMeta] = useState(null);
    const [allChaptersMetaLoaded, setAllChaptersMetaLoaded] = useState(false);
    // const [filler, setFiller] = useState(false);

    const [chpprog, setChpprog] = useState([]);
    const [chpprogLoaded, setChpprogLoaded] = useState(false);

    const [displayChapterList, setDisplayChapterList] = useState(false);
    const [htofua, setHtofua] = useState(120);
    const [proficiencyScore, setProficiencyScore] = useState(0);

    const { getUserResponseLSRecord, deleteUserResponseLSRecord } = useUserResponseLSRecord()

    // console.log(updateUserIdToUserResponses(getUserResponseLSRecord(), 'booi'))

    let data = [
        { green: htofd, StatusValue: 3, incStatusupanddown: true },
        { blue: htofc, StatusValue: 5, incStatusupanddown: false },
        { yellow: htofb, StatusValue: 3, incStatusupanddown: false },
        { red: htofa, StatusValue: 1, incStatusupanddown: true },
    ];

    useEffect(() => {

        setMaxNumberQuestion(
            largestOfNumbers(
                htofd,
                htofc,
                htofb,
                htofa
            )
        );
    }, [data, htofd, htofc, htofb, htofa]);

    useEffect(() => {
        if (maxNumberQuestion > 0) {
            setgreenQuestion((data[0].green * 100) / maxNumberQuestion + 7);
            setBlueQuestion((data[1].blue * 100) / maxNumberQuestion + 7);
            setyellowNumberQuestion((data[2].yellow * 100) / maxNumberQuestion + 7);
            setRedQuestion((data[3].red * 100) / maxNumberQuestion + 7);
        }
    }, [maxNumberQuestion, data]);

    const [initiatedChapters, setInitiatedChapters] = useState("");
    const [initiatedChaptersLoaded, setInitiatedChaptersLoaded] = useState(false);
    const [chapterList, setChapterList] = useState([]);
    const [chapter, setChapter] = useState([]);
    const [chapterMetaList, setChapterMetaList] = useState([]);

    const [userid, setUserid] = useState(null)

    useEffect(() => {
        // console.log(userid)
        auth.onAuthStateChanged(function (user) {
            // console.log("%c This is user", "color: red", user.phoneNumber)
            // if (user) setUserid(user.currentUser?.phoneNumber?.substring(1, user.currentUser?.phoneNumber?.length));
            // else setUserid(auth.currentUser?.email);
            if (user) setUserid(user?.phoneNumber?.substring(1, user.currentUser?.phoneNumber?.length));
            else setUserid(auth?.email);
        })
    }, [auth]);

    const [newChapters, setNewChapters] = useState([]);

    useEffect(() => {
        if (auth.currentUser) {
            async function getAllChaptersMeta() {
                try {
                    await axios
                        .get(
                            "https://7xb527e9w1.execute-api.ap-northeast-1.amazonaws.com/getAllChapterMEtadata/getallchaptermetadata",
                            {
                                headers: { "Content-Type": "application/x-www-form-urnlecoded", },
                            }
                        )
                        .then((response) => {
                            const data = response.data;
                            let adata = data;
                            setAllChaptersMeta(adata);
                            setChapterMetaList(adata);
                            setAllChaptersMetaLoaded(true);

                            // console.log(adata);
                        })
                        .catch((err) => console.log(err));
                } catch (e) {
                    console.log("caught err" + e.message);
                }
            }

            getAllChaptersMeta();
        }
    }, [userid, auth]);

    useEffect(() => {
        if (auth.currentUser) {
            async function getAllChaptersPremium() {
                try {
                    await axios
                        .get(
                            `https://kqd7mc4a26.execute-api.ap-northeast-1.amazonaws.com/getPremiumChapters/getpremiumchapters?UserID=${userid}`,
                            {
                                headers: {
                                    "Content-Type":
                                        "application/x-www-form-urlencoded",
                                },
                            }
                        )
                        .then((resp) => {
                            // console.log(resp)
                            const data = resp.data;
                            let adata = data;
                            // console.log(adata,"-----------------------------------------------------");
                            setAllChaptersPremium(adata);
                            setAllChaptersPremiumLoaded(true);
                            // setAllChaptersMetaLoaded(true);
                        })
                        .catch((err) => console.log(err));
                } catch (e) {
                    console.log("caught err" + e.message);
                }
            }

            getAllChaptersPremium();
        }
    }, []);

    useEffect(() => {
        if (auth.currentUser) {
            async function getInitiatedChapterIDs() {
                await axios
                    .get(
                        `https://p4r1inhvs3.execute-api.ap-northeast-1.amazonaws.com/getInitaitedChapterIDs/getinitiatedchapterids?UserID=${userid}`,
                        {
                            headers: { "Content-Type": "application/x-www-form-urlencoded", },
                        }
                    )
                    .then((res) => {
                        const data = res.data;
                        setInitiatedChapters(data);
                        setChapterList(data);
                        setInitiatedChaptersLoaded(true);
                    })
                    .catch((err) => console.log(err));
            }

            getInitiatedChapterIDs();
        }
    }, [
        auth.currentUser
            ? auth.currentUser.phoneNumber
                ? auth.currentUser.phoneNumber
                : auth.currentUser?.email
            : "", initiatedChaptersLoaded
    ]);

    useEffect(() => {
        if (auth.currentUser) {
            async function getUserChapterProgress() {
                setChpprog([]);

                if (initiatedChaptersLoaded && initiatedChapters.length > 0) {
                    const promises = await initiatedChapters.map((obj, idx) => {
                        return axios
                            .get(
                                "https://q03u3s13w3.execute-api.ap-northeast-1.amazonaws.com/getLastQuestion/getlastquestion?UserID=" +
                                userid +
                                "&ChapterID=" +
                                obj.ChapterID,
                                {
                                    headers: { "Content-Type": "application/x-www-form-urlencoded", },
                                }
                            )
                            .then((resp) => {
                                let adata = resp.data;
                                return axios
                                    .get(
                                        "https://eg0m3n8vrc.execute-api.ap-northeast-1.amazonaws.com/getChapterProgress/getchapterprogress?ChapterID=" +
                                        obj.ChapterID +
                                        "&QuestionID=" +
                                        adata[0]?.AttemptedTillQuestionID,
                                        {
                                            headers: {
                                                "Content-Type":
                                                    "application/x-www-form-urlencoded",
                                            },
                                        }
                                    )
                                    .then((resp) => {
                                        let cdata = resp.data;

                                        let ne = {
                                            chapid: obj.ChapterID,
                                            prog: cdata,
                                        };
                                        return ne;
                                    })
                                    .catch((err) => console.log(err));
                            })
                            .catch((err) => console.log(err));
                    });
                    // console.log(promises,"promises")

                    Promise.all(promises).then((results) => {
                        setChpprog(results);
                        setChpprogLoaded(true);
                    });
                }
            }
            getUserChapterProgress();
        }
    }, [
        auth.currentUser
            ? auth.currentUser.phoneNumber
                ? auth.currentUser.phoneNumber
                : auth.currentUser?.email
            : "",
        ,
        initiatedChapters,
        initiatedChaptersLoaded,
        chpprogLoaded,
    ]);

    const handleChapters = () => {
        const revisedIDs = chapterList.map((chapter) => chapter.ChapterID);
        const newChapters = chapterMetaList.filter(
            (chapter) => !revisedIDs.includes(chapter.ChapterID)
        );

        setNewChapters(newChapters);
    };

    function getSpecificChapterProgress(mychapid) {

        if (initiatedChaptersLoaded && chpprogLoaded && chpprog.length > 0) {
            let obj = chpprog.filter((mychap) => {
                // console.log(mychap?.chapid.trim() , mychapid.ChapterID)
                return mychap?.chapid.trim() === mychapid.ChapterID;
            });
            // console.log(obj)
            return obj;
        } else return null;
    }

    function getSpecificChapterMetaData(mychapid) {
        // console.log(mychapid, allChaptersMetaLoaded, allChaptersMeta)
        if (allChaptersMetaLoaded && allChaptersMeta.length > 0) {
            let obj = allChaptersMeta.filter((mychap) => {
                return mychap.ChapterID.trim() === mychapid;
            });
            return obj;
        }
    }

    function getSpecificChapterPremium(mychapid) {
        if (
            allChaptersMetaLoaded &&
            allChaptersPremiumLoaded &&
            allChaptersMeta.length > 0 &&
            allChaptersPremium.length > 0
        ) {
            // setHasPremium(false);
            // getFalse();
            let obj = allChaptersPremium.filter((mychap) => {
                return mychap.ChapterID.trim() === mychapid;
            });
            return obj;
            // setActiveChapterPremium(obj);
        } else return [];
    }

    useEffect(() => {
        if (numuniq === 0) {
            setProficiencyScore("n/a");
        } else {
            // console.log(htofa, htofb, htofc, htofd, numuniq);
            setProficiencyScore(
                parseFloat(
                    (htofa * 50 +
                        htofb * 70 +
                        htofc * 85 +
                        htofd * 100) /
                    numuniq
                ).toFixed(2)
            );
        }
    }, [htofa, htofb, htofc, htofd, numuniq]);

    useEffect(() => {
        if (auth.currentUser) {
            async function getUnattemptedQuestionNumber() {
                let url =
                    isChecked || chapter.length === 0
                        ? "https://q4ftpjfd13.execute-api.ap-northeast-1.amazonaws.com/getUnattemptedQuestions/getunattemptedquestions?UserID=" +
                        userid
                        : "https://q8rqzslgl0.execute-api.ap-northeast-1.amazonaws.com/getAttemptedQuestionNumber/getattemptedchapterquestionnumber?UserID=" +
                        userid +
                        "&ChapterID=" +
                        chapter[0].ChapterID;

                try {
                    const response = await axios.get(url, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    const data = await response.data;

                    if (isChecked || chapter.length === 0) {
                        setHtofua(data);
                    } else {
                        setHtofua(data[0]["count(distinct(QuestionID))"]);
                    }
                } catch (e) {
                    setHtofua(0);
                    console.log("caught err" + e.message);
                }
            }

            getUnattemptedQuestionNumber();
        }
    }, [
        auth.currentUser
            ? auth.currentUser.phoneNumber
                ? auth.currentUser.phoneNumber
                : auth.currentUser?.email
            : "",
        chapter,
        isChecked,
    ]);

    useEffect(() => {
    }, [currentChapter])

    useEffect(() => {
        if (auth.currentUser) {
            async function getChapterwiseTimeToComplete() {
                try {
                    await axios
                        .get(
                            "https://3zbl4cst14.execute-api.ap-northeast-1.amazonaws.com/getchapterwisetime/getchapterwisetime?UserID=" + userid,
                            {
                                headers: { "Content-Type": "application/x-www-form-urlencoded", },
                            }
                        )
                        .then((resp) => {
                            const data = resp.data;
                            setChapterwisetime(data);
                        })
                        .catch((err) => console.log(err));
                } catch (e) {
                    console.log("caught err" + e.message);
                }
            }
            getChapterwiseTimeToComplete();
        }
    }, [
        auth.currentUser
            ? auth.currentUser.phoneNumber
                ? auth.currentUser.phoneNumber
                : auth.currentUser?.email
            : "",
        numuniq,
    ]);

    useEffect(() => {
        // console.log(auth.currentUser, auth);

        if (auth) {
            async function getReviewGraphByUser() {
                let url =
                    isChecked || chapter.length === 0
                        ? "https://c29swv5kmg.execute-api.ap-northeast-1.amazonaws.com/getReviewGraph/getreviewgraph?UserID=" +
                        userid
                        : "https://9csqyfdrjk.execute-api.ap-northeast-1.amazonaws.com/getChapterwiseGraph/getchapterwisegraph?UserID=" +
                        userid +
                        "&ChapterID=" +
                        chapter[0].ChapterID;
                try {
                    await axios
                        .get(url, {
                            headers: {
                                "Content-Type":
                                    "application/x-www-form-urlencoded",
                            },
                        }).then((response) => {
                            const data = response.data;
                            // console.log(data);

                            setHtofA(data.A);
                            setHtofB(data.B);
                            setHtofC(data.C);
                            setHtofD(data.D);

                            setValueLoaded(true);
                            // data[0].green = data.D;
                            // data[1].blue = data.C;
                            // data[2].yellow = data.B;
                            // data[3].red = data.A;
                            // setIsLoaded(true);
                        });
                } catch (e) {
                    console.log("caught err " + e.message);
                }
            }
            getReviewGraphByUser();
        }
    }, [
        auth.currentUser
            ? auth.currentUser.phoneNumber
                ? auth.currentUser.phoneNumber
                : auth.currentUser?.email
            : "",
        chapter,
        auth,
        isChecked, data
    ]);

    useEffect(() => {
        if (auth.currentUser) {
            async function getTotalUniqueQuestions() {
                try {
                    const response = await axios.get(
                        "https://zhxvcqlrf7.execute-api.ap-northeast-1.amazonaws.com/getNumberOfUniqueQuestions/getnumberofuniquequestions?UserID=" +
                        userid,
                        {
                            headers: {
                                "Content-Type":
                                    "application/x-www-form-urlencoded",
                            },
                        }
                    );
                    const data = await response.data;
                    setNumUniq(data);
                    // setIsLoadedUniq(true);
                } catch (e) {
                    console.log("caught err" + e.message);
                }
            }

            getTotalUniqueQuestions();
        }
    }, [
        auth.currentUser
            ? auth.currentUser.phoneNumber
                ? auth.currentUser.phoneNumber
                : auth.currentUser?.email
            : "",
        userid,
        numuniq,
    ]);

    // adding user response from LS to DB

    const handleAddingResponseToDb = async () => {
        if (userid) {
            const status = await addUserResponseToDb(getUserResponseLSRecord(), userid)
            if (status) deleteUserResponseLSRecord()
        }
    }

    useEffect(() => {
        handleAddingResponseToDb()
    }, [userid])

    function checkChapterInitialized(mychap) {
        // console.log("ccinit");
        console.log(mychap);
        if (!mychap) {
            // console.log("No chapter Initialized --> Redirecting to selection");
            navigate("/chapter-selection");
            return;
        }

        try {
            axios
                .get(
                    "https://p4r1inhvs3.execute-api.ap-northeast-1.amazonaws.com/getInitaitedChapterIDs/getinitiatedchapterids?UserID=" +
                    userid,
                    {
                        headers: { "Content-Type": "application/x-www-form-urlencoded", },
                    }
                )
                .then((resp) => {
                    let adata = resp.data;
                    // console.log("ADATA", adata);

                    if (adata.length === 0) {
                        initializeChapterAndStartDeck(mychap);
                    } else {
                        adata.filter((myc) => {
                            // console.log("ccinit222", adata);
                            // console.log(mychap);
                            if (
                                myc.ChapterID.trim() === mychap.ChapterID.trim()
                            ) {
                                // console.log("ccinit444");
                                checkChapterProgressAndStartDeck(
                                    mychap.ChapterID
                                );
                            } else {
                                initializeChapterAndStartDeck(mychap);
                            }
                        });
                    }
                })
                .catch((err) => console.log(err));
        } catch (e) {
            // setFiller(false);
            console.log("caught err" + e.message);
        }
    }

    function initializeChapterAndStartDeck(mychap) {
        // get chapter start question id
        // initialize chapter status record with
        console.log("entered");

        try {
            axios
                .get(
                    "https://falpuarwj7.execute-api.ap-northeast-1.amazonaws.com/getChapterStartQuestionID/getchapterstartquestionid?ChapterID=" +
                    mychap.ChapterID,
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((resp) => {
                    let adata;
                    if (resp.data.length !== 0) {
                        adata = resp.data[0];
                    } else {
                        adata = { QuestionID: mychap.ChapterID + "_1" };
                    }

                    axios
                        .get(
                            "https://josa0h5uvb.execute-api.ap-northeast-1.amazonaws.com/InitializeChapterStatus/initializechapterstatus?UserID=" +
                            userid +
                            "&ChapterID=" +
                            mychap.ChapterID +
                            "&QuestionID=" +
                            adata.QuestionID,
                            {
                                headers: {
                                    "Content-Type":
                                        "application/x-www-form-urlencoded",
                                },
                            }
                        )
                        .then(() => {
                            axios
                                .get(
                                    "https://f1nohln2og.execute-api.ap-northeast-1.amazonaws.com/getNewDeck/getnewdeck?ChapterID=" +
                                    mychap.ChapterID +
                                    "&QuestionID=" +
                                    adata.QuestionID,
                                    {
                                        headers: { "Content-Type": "application/x-www-form-urlencoded", },
                                    }
                                )
                                .then((resp) => {
                                    let adata;
                                    if (resp.data.length !== 0) {
                                        adata = resp.data;
                                    } else {
                                        adata = [
                                            { QuestionID: mychap.ChapterID + "_1", },
                                        ];
                                    }

                                    let myurlstr =
                                        "https://donvtn5h2b.execute-api.ap-northeast-1." +
                                        "amazonaws.com/addDeckHistoryRecord/adddeckhistoryrecord?" +
                                        "UserID=" +
                                        userid +
                                        "&DeckID=" +
                                        userid +
                                        "_" +
                                        new Date()
                                            .toISOString()
                                            .slice(0, 19)
                                            .replace("T", " ") +
                                        "&ChapterID=" +
                                        mychap.ChapterID +
                                        "&DeckStartTime=" +
                                        new Date()
                                            .toISOString()
                                            .slice(0, 19)
                                            .replace("T", " ") +
                                        "&DeckQuestionIDs=" +
                                        adata
                                            .map((x) => x.QuestionID)
                                            .join(",") +
                                        "&LastQuestionIDAttempted=" +
                                        adata[0].QuestionID +
                                        "&DeckCompleted=0&DeckExitTime=" +
                                        new Date()
                                            .toISOString()
                                            .slice(0, 19)
                                            .replace("T", " ") +
                                        "&PercentageChapterCompletionScore=0.0";

                                    axios
                                        .get(myurlstr, {
                                            headers: {
                                                "Content-Type":
                                                    "application/x-www-form-urlencoded",
                                            },
                                        })
                                        .then(() => {
                                            // resp.data.then((data) => {
                                            // let mdta = data;

                                            // console.log(adata);

                                            // console.log(
                                            //     "=================================="
                                            // );
                                            navigate("/chapter-practice", {
                                                state: {
                                                    ChapterID: mychap.ChapterID,
                                                    QuestionIDs: adata,
                                                },
                                            });
                                        })
                                        .catch((err) => console.log(err));
                                    // });
                                })
                                .catch((err) => console.log(err));
                            // });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => console.log(err));
        } catch (e) {
            setFiller(false);
            console.log("caught err" + e.message);
        }
    }

    function checkChapterProgressAndStartDeck() {
        let mychapid;
        if (chapter.length !== 0) {
            mychapid = chapter[0].ChapterID;
        } else {
            mychapid = chapterList[0].ChapterID;
        }

        try {
            // get last question first - if dk in progress -
            // get deck using firstquestion and start at last q

            // get last question first - if dk is not in progress
            // get dk using last question - start at last q

            axios
                .get(
                    "https://falpuarwj7.execute-api.ap-northeast-1.amazonaws.com/getChapterStartQuestionID/getchapterstartquestionid?ChapterID=" +
                    mychapid,
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then(() => {
                    // resp.data.then((data) => {
                    // let myfirstques = data[0];

                    let lastqstr =
                        "https://q03u3s13w3.execute-api.ap-northeast-1.amazonaws.com/getLastQuestion/getlastquestion?UserID=" +
                        userid +
                        "&ChapterID=" +
                        mychapid;

                    axios
                        .get(lastqstr, {
                            headers: {
                                "Content-Type":
                                    "application/x-www-form-urlencoded",
                            },
                        })
                        .then((resp) => {
                            // resp.data.then((data) => {
                            let mylastques = resp.data[0];

                            // now axios.get inprogressdeckallinfo - if deck exists
                            // axios.get deck using first question
                            // if deck does not exist - axios.get deck using last question

                            axios
                                .get(
                                    "https://6h0gl6fat7.execute-api.ap-northeast-1.amazonaws.com/getDeckInProgressAllInfo/getdeckinprogressallinfo?UserID=" +
                                    userid +
                                    "&ChapterID=" +
                                    mychapid,
                                    {
                                        headers: {
                                            "Content-Type":
                                                "application/x-www-form-urlencoded",
                                        },
                                    }
                                )
                                .then((resp) => {
                                    // resp.data.then((data) => {
                                    let dekrec = resp.data;
                                    ///////start block getting deck if chapter is in progress

                                    //console.log("deckrecords==", dekrec);

                                    if (dekrec.length === 0) {
                                        // axios.get new deck ///////

                                        axios
                                            .get(
                                                "https://f1nohln2og.execute-api.ap-northeast-1.amazonaws.com/getNewDeck/getnewdeck?ChapterID=" +
                                                mychapid +
                                                "&QuestionID=" +
                                                mylastques.AttemptedTillQuestionID,
                                                {
                                                    headers: {
                                                        "Content-Type":
                                                            "application/x-www-form-urlencoded",
                                                    },
                                                }
                                            )
                                            .then((resp) => {
                                                // resp.data.then((data) => {
                                                let nwdk = resp.data;

                                                // insert adddeckhistory record here /////

                                                let myurlstr =
                                                    "https://donvtn5h2b.execute-api.ap-northeast-1." +
                                                    "amazonaws.com/addDeckHistoryRecord/adddeckhistoryrecord?" +
                                                    "UserID=" +
                                                    userid +
                                                    "&DeckID=" +
                                                    userid +
                                                    "_" +
                                                    new Date()
                                                        .toISOString()
                                                        .slice(0, 19)
                                                        .replace("T", " ") +
                                                    "&ChapterID=" +
                                                    mychapid +
                                                    "&DeckStartTime=" +
                                                    new Date()
                                                        .toISOString()
                                                        .slice(0, 19)
                                                        .replace("T", " ") +
                                                    "&DeckQuestionIDs=" +
                                                    nwdk
                                                        .map(
                                                            (x) => x.QuestionID
                                                        )
                                                        .join(",") +
                                                    "&LastQuestionIDAttempted=" +
                                                    mylastques.AttemptedTillQuestionID +
                                                    "&DeckCompleted=0&DeckExitTime=" +
                                                    new Date()
                                                        .toISOString()
                                                        .slice(0, 19)
                                                        .replace("T", " ") +
                                                    "&PercentageChapterCompletionScore=0.0";

                                                // //console.log(
                                                //     "myurlstr==",
                                                //     myurlstr
                                                // );

                                                axios
                                                    .get(myurlstr, {
                                                        headers: {
                                                            "Content-Type":
                                                                "application/x-www-form-urlencoded",
                                                        },
                                                    })
                                                    .then(() => {
                                                        // resp.data.then(
                                                        //     (data) => {
                                                        //         // let mdta = data;
                                                        //     }
                                                        // );
                                                    });

                                                navigate("/chapter-practice", {
                                                    state: {
                                                        ChapterID: mychapid,
                                                        QuestionIDs: nwdk,
                                                        AttemptedTill:
                                                            mylastques.AttemptedTillQuestionID,
                                                    },
                                                });
                                                // //console.log(
                                                //     "it is navigating to chapter review"
                                                // );
                                                // });
                                            })
                                            .catch((err) => console.log(err));
                                    }

                                    if (dekrec.length >= 1) {
                                        /*
                                                 axios.get('https://f1nohln2og.execute-api.ap-northeast-1.amazonaws.com/getNewDeck/getnewdeck?ChapterID='+mychapid+'&QuestionID='+mylastques.AttemptedTillQuestionID, {
                                                   method: 'GET', 
                                                   headers: {
                                                        'Content-Type': 'application/x-www-form-urlencoded',              
                                                      }   
                                                }).then((resp) => {
                                                  resp.data.then((data) => {
                                                  let adata = data;
                                                */

                                        const arr =
                                            dekrec[0].DeckQuestionIDs.split(
                                                ","
                                            );

                                        const obj3 = [];

                                        arr.forEach((element) => {
                                            obj3.push({ QuestionID: element });
                                        });

                                        navigate("/chapter-practice", {
                                            state: {
                                                ChapterID: mychapid,
                                                QuestionIDs: obj3,
                                                AttemptedTill:
                                                    mylastques.AttemptedTillQuestionID,
                                            },
                                        });
                                        // //console.log(
                                        //     "it is navigating to chapter review"
                                        // );
                                        /*

                                                        });
                                            });
                                                        */
                                    }
                                })
                                .catch((err) => console.log(err));
                            // });
                        });
                    // });
                })
                .catch((err) => console.log(err));
        } catch (e) {
            console.log("caught err" + e.message);
        }
    }

    // async function prepareReviewDeckAndLaunch() {
    //     try {
    //         await axios.get(`https://0o6gj9yp99.execute-api.ap-northeast-1.amazonaws.com/getReviewDeck/getreviewdeck?UserID=${userid}`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/x-www-form-urlencoded",
    //                 },
    //                 cors: "no cors",
    //             }
    //         ).then((resp) => {

    //             // console.log(resp.data);
    //             const data = resp.data;
    //             const arr = data;
    //             const obj3 = [];
    //             arr.forEach((element) => {
    //                 obj3.push({ QuestionID: element });
    //             });
    //             window.sessionStorage.clear();
    //             navigate("/review-mode", {
    //                 state: {
    //                     ChapterID: "ReviewMode",
    //                     QuestionIDs: obj3
    //                 },
    //             });
    //         }).catch((err) => {
    //             console.log(err);
    //         });
    //     } catch (e) {
    //         console.log("caught err" + e.message);
    //     }
    // }

    async function handlePrepareReviewDeck(userid) {
        const questionIds = await prepareReviewDeckAndLaunch(userid)
        navigate("/review-mode", {
            state: {
                ChapterID: "ReviewMode",
                QuestionIDs: questionIds
            },
        });
    }

    const [numofques, setNumofques] = useState(0);

    async function getNumOfQuestion(chapid) {
        try {
            axios.get("https://yoh3xeyt4m.execute-api.ap-northeast-1.amazonaws.com/getNumberOfQuestionsInChapter/getnumberofquestionsinchapter?ChapterID=" +
                chapid,
                {
                    headers: { "Content-Type": "application/x-www-form-urlencoded", },
                }
            ).then((resp) => {
                setNumofques(resp.data[0]["COUNT(ChapterID)"]);
            })
                .catch((err) => console.log(err));
        } catch (e) {
            setNumofques(0);
            console.log("caught err" + e.message);
        }
    }

    useEffect(() => {
        if (isChecked || chapter.length === 0) {
            setNumofques(htofd + htofc + htofb + htofa)
        }
    }, [htofd, htofc, htofb, htofa, chapter])

    return (
        <>
            <Helmet>
                <title> Niopractice - Dashboard </title>
                <meta
                    name="Description"
                    content="Stay on top of your revision with our AI powered dashboard. Track your progress and receive daily practice plans tailored to your goals. Start maximizing your study time today"
                />
            </Helmet>

            <div className="dashboard">

                <div className="dashboard-main">
                    <div className="dashboard-main__info-container">
                        <ChapterDropdown
                            getNumOfQuestion={getNumOfQuestion}
                            displayChapterList={displayChapterList}
                            setDisplayChapterList={setDisplayChapterList}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            chapter={chapter}
                            setChapter={setChapter}
                            numofques={numofques}
                            chapterwisetime={chapterwisetime}
                            handleChapters={handleChapters}
                            chapterList={chapterList}
                            newChapters={newChapters}
                            getSpecificChapterMetaData={getSpecificChapterMetaData}
                            getSpecificChapterProgress={getSpecificChapterProgress}
                            getSpecificChapterPremium={getSpecificChapterPremium}
                        />
                    </div>

                    <div className="dashboard-main__stats-container ">
                        <ProgressStats
                            data={data}
                            chapter={chapter}
                            greenQuestion={greenQuestion}
                            BlueQuestion={BlueQuestion}
                            YellowQuestion={YellowQuestion}
                            RedQuestion={RedQuestion}
                            proficiencyScore={proficiencyScore}
                            userid={userid}
                            handlePrepareReviewDeck={handlePrepareReviewDeck}
                            valueLoaded={valueLoaded}
                            checkChapterInitialized={checkChapterInitialized}
                        />
                    </div>

                    <div className="newQuestionsAndStartNowBtnDiv">
                        <div className="NewQuestionQuestionStartAndtartChapter">
                            <h2 className="app-bold">{htofua} </h2>
                            <span className="text-new-question">
                                {''}New Questions
                            </span>
                        </div>

                        <button className="newChapterStartNowNavToNewLinks"
                            onClick={() => checkChapterInitialized(chapter[0])} >
                            Start Now
                        </button>
                    </div>

                </div>

                <DashboardFooter />
            </div>
        </>
    );
}

export default Dashboard;

