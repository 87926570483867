import React from 'react';

function BlogTags({ btnClickHandler }) {
    return (
        <div className='blog-page__header-tags-container'>
            <button className='blog-page__header-tag green' onClick={() => btnClickHandler("Speed")}> Speed </button>
            <button className='blog-page__header-tag blue' onClick={() => btnClickHandler("JEE")}> JEE </button>
            <button className='blog-page__header-tag red' onClick={() => btnClickHandler("Maths")}> Maths </button>
            <button className='blog-page__header-tag orange' onClick={() => btnClickHandler("Self-Learn")}> Self-Learn </button>
        </div>
    )
}

export default BlogTags