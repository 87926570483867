import React from 'react'
import './overlay-loader.css'

function OverLayLoader() {
    return (
        <div className='overlay-loader'>
            <div className="lds-dual-ring" />
        </div>
    )
}

export default OverLayLoader