
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MathJaxContext } from "better-react-mathjax";

import { Provider } from 'react-redux';
import store from "./redux/app/store";

import Navbar from "./components/navbar/Navbar";
import Dashboard from './components/dashboard/Dashboard';
import Landingpage from './components/landingpage/Landingpage';

import ChapterSelection from "./components/chapter/ChapterSelection";
import ChapterDetail from "./components/chapterDetail/ChapterDetail";
import ChapterPractice from "./components/chapterPractice/ChapterPractice";
import ChapterTrial from "./components/chapterTrial/ChapterTrial";
import QuestionCheck from "./components/questionCheck/QuestionCheck";
import QuestionTester from "./components/questionTester/QuestionTester";
import ReviewMode from "./components/chapterReview/ReviewMode";

import SetCompletion from "./components/completion/SetCompletion";
import ChapterCompletion from "./components/completion/ChapterCompletion";
import ReviseCompletion from "./components/completion/ReviseCompletion";

import ContactUs from "./page/contact/ContactUs";
import About from "./page/about/About";
import SignIn from "./page/signIn";
import OurTeam from "./page/team/OurTeam";
import Blog from './page/blog/BlogPage';
import BlogView from './page/blog/BlogView';
import Profile from "./components/profile/Profile";
// import PremiumChapterPurchase from "./components/premiumChapterPurchase";

import PricingPolicy from "./page/pricing/PricingPolicy";
import CancellationAndRefund from "./page/cancellationAndRefund/CancellationAndRefund";
import PrivacyPolicy from "./page/privacy/PrivacyPolicy";
import TemsAndConditions from "./page/termsAndConditions/TermsAndConditions";
import Sitemap from "./Sitemap";

import ErrorPage from "./components/errorPage/ErrorPage";
import Preloader from "./components/loading/Preloader";

import ProtectedComponent from "./components/protectedComponent/ProtectedComponent";

import FirebaseAuthContextProvider from "./context/FirebaseAuthContext";
import FirebaseContextProvider from "./context/FirebaseContext";

import useLocalUserData from "./localStorage/useLocalUserData";
import useNioclassTutorialRecord from "./localStorage/useNioclassTutorialRecord";

import "./App.css";

function App() {

    const { createAnonymousLocalUserData } = useLocalUserData()
    const { setTutorialRecord } = useNioclassTutorialRecord()

    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState();

    const config = {
        tex: { inlineMath: [["$", "$"], ["\\(", "\\)"]], },
    };

    useEffect(() => {
        createAnonymousLocalUserData()
        setTutorialRecord()
    }, []);

    return (
        <div className="app">
            <Provider store={store}>
                {!loaded && <Preloader />}
                <MathJaxContext config={config}>
                    <FirebaseContextProvider>
                        <FirebaseAuthContextProvider>
                            <Router>
                                {/* {!(location.pathname==="/sitemap.xml")&& */}
                                <Navbar
                                    user={user}
                                    setUser={setUser}
                                    loaded={loaded}
                                    setLoaded={setLoaded} />
                                {/* } */}
                                <Routes>
                                    <Route path='/' element={<Landingpage />} />
                                    {/* <Route path='/' element={(user===null) ? <Navigate to="/landing-page" replace  /> : <Navigate to="/dashboard" />} /> */}
                                    <Route path='/landing-page' element={<Landingpage />} />
                                    <Route path="/chapter-selection" element={<Landingpage />} />
                                    <Route path="/sign-in" element={<Landingpage />} />

                                    <Route path="/chapter-trial" element={<Landingpage />} />
                                    <Route path="/chapter-detail" element={<Landingpage />} />

                                    {/* protected routes */}
                                    <Route path="dashboard" element={<ProtectedComponent component={Landingpage} />} />
                                    <Route path="my-profile" element={<ProtectedComponent component={Landingpage} />} />
                                    <Route path="chapter-practice" element={<ProtectedComponent component={Landingpage} />} />
                                    <Route path="review-mode" element={<ProtectedComponent component={Landingpage} />} />

                                    {/* <Route path="/p" element={<PremiumChapterPurchase />} /> */}

                                    {/* completion routing */}
                                    <Route path="/deck-completion" element={<Landingpage />} />
                                    <Route path="/chapter-completion" element={<Landingpage />} />
                                    <Route path="/review-completion" element={<Landingpage />} />

                                    {/* QuestionTesterRoutes */}
                                    <Route path="/question-tester" element={<Landingpage />} />
                                    <Route path="/question-check" element={<Landingpage />} />




                                    {/* miscellaneous routing */}
                                    <Route path="/about-us" element={<About />} />
                                    <Route path="/contact-us" element={<ContactUs />} />
                                    <Route path="/our-team" element={<OurTeam />} />
                                    <Route path="/terms-and-conditions" element={<TemsAndConditions />} />
                                    <Route path="/cancellation-and-refund-policy" element={<CancellationAndRefund />} />
                                    <Route path="/pricing-policy" element={<PricingPolicy />} />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                                    <Route path="/faq" element={<About />} />
                                    <Route path="/sitemap" element={<Sitemap />} />
                                    <Route path="/blog" element={<Blog />} />
                                    <Route path="/blog/:blogId" element={<BlogView />} />

                                    <Route path="/tutorial" element={<Landingpage />} />

                                    <Route path="*" element={<ErrorPage />} />
                                </Routes>
                            </Router>

                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />

                        </FirebaseAuthContextProvider>
                    </FirebaseContextProvider>
                </MathJaxContext>
            </Provider>
        </div>
    );
}
export default App;
