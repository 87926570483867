import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tutorialStep: 1
}

const tutorialSlice = createSlice({
    name: 'tutorialSlice',
    initialState: initialState,
    reducers: {
        setTutorialStep: (state, action) => {
            state.tutorialStep = action.payload;
        },
        resetTutorialStep: (state) => {
            state.tutorialStep = 1;
        }
    }
})


export default tutorialSlice.reducer;
export const { setTutorialStep, resetTutorialStep } = tutorialSlice.actions;