import teamMembers from "../../utils/teamMembers";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useScrollToTop from "../../hooks/useScrollToTop";

const OurTeam = () => {

    useScrollToTop()

    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesTab = useMediaQuery(theme.breakpoints.down("lg"));
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#f6f6f6",
                    width: matchesMD ? "100%" : matchesTab ? "90%" : "80%",
                    margin: "auto",
                    padding: 0,
                    alignSelf: "center",
                    border: 0,
                    borderColor: "#29476a",
                    marginBottom: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: matchesMD ? 0 : matchesTab ? 5 : "",
                }}
            >
                {/* <Header /> */}

                <Typography
                    sx={{
                        padding: 2,
                        textAlign: "center",
                        paddingBottom: 0.25,
                        fontSize: matchesMD ? 32 : 40,
                        fontFamily: "OpenSansSemiBold",
                        color: "#14327d",
                        paddingTop: "3em",
                    }}
                >
                    Our Team
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        position: "relative",
                        width: "100%",
                        marginTop: "3em",
                        marginLeft: matchesTab ? -1.75 : -2.5,
                    }}
                >
                    {teamMembers.map((member) => {
                        return (
                            <Box
                                key={member.id}
                                sx={{
                                    width: matchesMD ? "90%" : "45%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: matchesTab ? 4 : 5,
                                    padding: 3,
                                    marginBottom: 4,
                                    backgroundColor: "#eaf1f4",
                                    alignItems: "center",
                                    borderRadius: 2,
                                }}
                            >
                                <img
                                    style={{
                                        width: matchesMD ? "45%" : "100%",
                                        height: "150px",
                                    }}
                                    src={member.image}
                                    alt="member"
                                />

                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: matchesMD
                                                ? 24
                                                : matchesTab
                                                    ? 26
                                                    : 30,
                                            padding: 2,
                                            paddingTop: 0,
                                            paddingBottom: 1,
                                            fontFamily: "OpenSansSemiBold",
                                        }}
                                    >
                                        {member.name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            padding: 2,
                                            paddingTop: 0,
                                            paddingBottom: 0.25,
                                            fontSize: matchesMD
                                                ? 14
                                                : matchesTab
                                                    ? 16
                                                    : 18,
                                            fontFamily: "OpenSansSemiBold",
                                        }}
                                    >
                                        {member.role}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            padding: 2,
                                            paddingTop: 0.25,
                                            paddingBottom: 0.25,
                                            fontSize: matchesMD
                                                ? 10
                                                : matchesTab
                                                    ? 12
                                                    : 14,
                                            fontFamily: "OpenSansSemiBold",
                                        }}
                                    >
                                        {member.description}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </>
    );
};

export default OurTeam;
