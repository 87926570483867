import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import blogData from './data/blogData';
import BlogTags from './BlogTags';
import Footer from '../../components/landingpage/Footer';

import { searchIcon, searchIconFill } from '../../assets/icons';

import BlogArticleCard from './BlogArticleCard';

import './blog-page.css'

function BlogPage() {

    const [blogTags, setBlogTags] = useState([])
    const [activeFilter, setActiveFilter] = useState('JEE')

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    useEffect(() => {
        scrollToTop()
    }, [])

    useEffect(() => {
        let tags = []
        blogData.forEach(blog => {
            tags = [...tags, ...blog.tags]
        });
        const uniqueTags = [...new Set(tags)]
        setBlogTags(uniqueTags)
    }, [])

    const handleSetFilter = (value) => {
        setActiveFilter(value)
    }

    return (
        <>
            <div className='blog-page-container'>
                <div className='blog-page'>
                    <div className='blog-page__header'>
                        <div className='blog-page__header-left'>
                            <h1 className='blog-page__header-title'> Blogs </h1>
                            <div className='blog-page__header-search-container'>
                                <img className='blog-page__header-search-icon' src={searchIcon} />
                                <input className='blog-page__header-search-input'></input>
                            </div>
                        </div>

                        {/* <div className='blog-page__header-tags-container'>
                    {blogTags.map((tag) => <div className='blog-page__header-tag'>{tag}</div>)}
                </div> */}

                        <BlogTags btnClickHandler={handleSetFilter} />

                    </div>

                    <div className='blog-page__filter-indicator'>
                        <div className='blog-page__filter-active'> {activeFilter}</div>
                        <div className='blog-page__horizontal-line' />
                    </div>

                    <div className='blog-page__articles-container'>
                        {blogData.map((blog) =>
                            <BlogArticleCard key={blog.id} blog={blog} />
                        )}
                    </div>

                    <div className='blog-page__filter-indicator'>
                        <div className='blog-page__filter-active'> {activeFilter}</div>
                        <div className='blog-page__horizontal-line' />
                    </div>
                    <div className='blog-page__related-articles'>
                        {blogData.slice(0, 2).map((blog) =>
                            <BlogArticleCard key={blog.id} blog={blog} />
                        )}
                    </div>

                </div >
            </div>
            <Footer />
        </>
    )
}

export default BlogPage;