import React from 'react';
import './error-page.css';

function ErrorPage() {
    return (
        <div className='error-page'>

            <div className='error-page-container'>
                <h1 className='error-page__title'> Opps! Page not found</h1>
                <p className='error-page__sub-title'> Maybe you can find something interesting on the home page!</p>
                <a href="/">

                <button className='error-page-btn app-btn-1'>
                    Go to home page
                </button>
                </a>
            </div>

        </div>
    )
}

export default ErrorPage