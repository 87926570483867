import React from 'react';
import { Link } from 'react-scroll';

// const vidURL = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/finalv+(2).mp4"

function hero(props) {

    return (
        <div className="hero-section-bg">
            <div className="hero-section">
                <div className="hero-section__container">
                    <h1 className='hero-section__title'>  AI-Driven JEE Maths Practice. </h1>
                    <div className="hero-section__sub-title">
                        Practice strategies, remove calculations, and learn{" "}
                        <em className='hero-section__sub-title__accented-text'>fast.</em>
                    </div>

                    <Link to="contact-form" spy={true} smooth={true} offset={50} duration={1500}>
                        <button className="app-btn-1">
                            Contact for a Demo
                        </button>
                    </Link>

                </div>

                {/* <div className="">
                    <video controls loop autoPlay={true} muted
                        className="hero-section__video"
                    >
                        <source type="video/mp4" src={vidURL} />
                        Sorry, your browser doesn't 100tcsopacitysupport
                        embedded videos.
                    </video>
                </div> */}
            </div>
        </div >
    )
}

export default hero