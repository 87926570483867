import React from 'react'

function BlogBulletList({ blogList }) {
    return (
        <ul className={`blog-body-item blog-bullet-list`}  >

            {blogList.map((list, idx) => (
                <li key={idx} className={`blog-number-list__item ${idx === blogList?.length - 1 ? 'no-margin' : ''}`}>
                    <span className='blog-bullet-list__bullet' />
                    <span className='blog-number-list__title'> {list.title} </span> :
                    <p className='blog-number-list__text'>{list.text} </p>
                </li>)
            )
            }
        </ul>
    )
}

export default BlogBulletList