import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import Footer from "../../components/landingpage/Footer";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import useScrollToTop from "../../hooks/useScrollToTop";
import SwiperBtnPrev from "./SwiperBtnPrev";
import SwiperBtnNext from "./SwiperBtnNext";

import posts from '../blog/data/blogData';

import {
    dbImg, dbImg2, img1, img2, img3, img4,
    manavProfile, himanshuProfile, kunalProfile, alvinProfile
} from "../../assets/images";

import { swipeNextIcon } from '../../assets/icons'

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import './about.css';
import './about-swiper.css';

function BlogsCarousel({ post }) {

    const navigate = useNavigate()

    useScrollToTop()

    const handleNavigateToBlogView = (blogId) => {
        navigate(`/blog/${blogId}`);
    };

    return (
        <div className="blog-carousel__card"
            onClick={() => handleNavigateToBlogView(post.id)}
        >
            <img
                alt={post?.blogTitle}
                src={post?.imgURL}
                className="blog-carousel__card-img"
            />

            <div className="">
                <div className="blog-carousel__card-title">
                    {post?.blogTitle.split(" ").slice(0, 5).join(" ")}...
                </div>
                <div className="blog-carousel__card-sub-title">
                    {post?.blogArticle.split(" ").slice(0, 15).join(" ")}
                    ...
                </div>
                <div className="blog-carousel__card-date">
                    <span className=""> {post?.publishedDate}</span>
                </div>
            </div>
        </div>
    );
}

export function AboutUs() {

    const navigate = useNavigate()

    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    const [isSwiper, setIsSwiper] = useState(false)

    const breakpoints = {
        600: {
            slidesPerView: 1,
        },

        800: {
            slidesPerView: 2,
        },

    };

    const handleGetInTouchBtn = () => {
        navigate('/contact-us')
    }

    useEffect(() => {
        if (prevRef.current && nextRef.current) setIsSwiper(true)
    }, [prevRef.current, nextRef.current])

    return (
        <>
            <div className="about">

                <div className="about__title">About NioMath</div>

                <div className="about__container about__header">

                    <div className="about__inner-container about-hero-paragraph">

                        <p className="about__sub-text">NioMath is an <span className="app-accented app-bold">AI-based math practice engine</span> that is specifically designed to optimze learning and exam preparedness.</p>
                        <p className="about__sub-text">It’s software delivers a unique, segmented, <span className="app-accented app-bold">strategy-first approach</span> for rapidly developing mathematics problem-solving skills and pre-college and college levels.</p>

                        <button onClick={handleGetInTouchBtn}
                            className="app-btn-1 about__button">
                            Get In Touch!
                        </button>
                    </div>

                    <div className="about-img-main app-img-border-rotate large ">
                        <img src={dbImg} alt="NioClass" className="about-hero-img hide-small" />
                        <img src={dbImg2} alt="NioClass" className="blog-img-2 hide-big" />
                    </div>
                </div>

                {/* 2 */}

                <div className="about__container-small">

                    <div className="about__container reverse">

                        <div className="about__inner-container">
                            <p className="about__sub-text">Based on rigorous evidence and research-backed principles from cognitive & behavioural psychology as well as cognitive neuroscience, the software ensures <span className="app-accented app-bold">effective and optimal skill acquisition.</span></p>
                        </div>

                        <div className="about__inner-container">
                            <img src={img3} alt="nioclass" className="blog-img-2" />
                        </div>

                    </div>

                    {/* 3 */}
                    <div className="about__container">

                        <div className="about__inner-container">
                            <p className="about__sub-text">
                                Its algorithm specifically enhances speed, and consistent usage is shown <span className="app-accented app-bold">increase focus and confidence.<br /> </span> Currently, NioMath covers all the topics for JEE Advanced mathematics.
                            </p>
                        </div>

                        <div className="about__inner-container">
                            <img src={img2} alt="nioclass" className="blog-img-3" />
                        </div>
                    </div>

                    {/* 4 */}
                    <div className="about__container reverse">

                        <div className="about__inner-container">
                            <p className="about__sub-text">The software, pedagogy and learning designs are continually being updated to <span className="app-accented app-bold">increase effectiveness and reach wider audiences.</span> </p>
                        </div>

                        <div className="about__inner-container">
                            <img src={img4} alt="nioclass" className="blog-img-4" />
                        </div>

                    </div>

                    {/* 5 */}
                    <div className="about__container-vision">

                        <div className="about__inner-container">
                            <h1 className="about-vision__title">Our Vision</h1>

                            <p className="about__sub-text">
                                NioMath’s vision is to optimize students’ learning.
                            </p>
                            <p className="about__sub-text">
                                It aims to build the most effective product for students to acquire skills and prepare for any academic examination.
                            </p>
                        </div>

                        <div className="app-center">
                            <div className="app-img-border-rotate">
                                <img src={img1} alt="nioclass" className="blog-img-vision" />
                            </div>
                        </div>

                    </div>

                    {/* 5 */}
                    <div className="about__container-team">

                        <h1 className="about__team__title">Our Team</h1>
                        <p className="about__sub-text">NioMath’s vision is to optimize students’ learning. </p>
                        <p> It aims to build the most effective product for students to acquire skills and prepare for any academic examination.</p>

                        <div className="about__inner-container-team">
                            <div className="about__inner-container team-leader">
                                <div className="about__team-member">
                                    <div className="about__team-member-img">
                                        <img src={manavProfile} alt="nioclass founder" />
                                    </div>

                                    <div className="about__team-member-name">Manav Kothari</div>
                                    <p className="about__sub-text">Founder</p>
                                </div>

                            </div>

                            <div className="about__container-team-members">

                                <div className="about__team-member">
                                    <div className="about__team-member-img">
                                        <img src={himanshuProfile} alt="team member" />
                                    </div>
                                    <div className="about__team-member-name"> Himanshu Johar </div>
                                    <p className="about__sub-text">Marketing Specialist</p>
                                </div>

                                <div className="about__team-member">
                                    <div className="about__team-member-img">
                                        <img src={kunalProfile} alt="team member" />
                                    </div>
                                    <div className="about__team-member-name">Kunal Gupta</div>
                                    <p className="about__sub-text">Software Engineer</p>
                                </div>

                                <div className="about__team-member">
                                    <div className="about__team-member-img">
                                        <img src={alvinProfile} alt="team member" />
                                    </div>
                                    <div className="about__team-member-name">Alvin Bhatnagar </div>
                                    <p className="about__sub-text">UI/UX Designer</p>
                                </div>

                            </div>
                        </div>

                    </div>

                </div >

                {/* blog carousel */}

                <div className="about__blog-carousel">

                    <h1 className="blog-carousel__title">Read Our Blogs</h1>

                    <div className="about-us__swiper-container">

                        <button className="blog-swipe-btn -left" ref={prevRef}>
                            <img src={swipeNextIcon} className="blog-swipe-btn-icon flip-x" />
                        </button>

                        <button className="blog-swipe-btn -right" ref={nextRef}>
                            <img src={swipeNextIcon} />
                        </button>

                        {isSwiper &&
                            <div className='about-us__swiper'>
                                <Swiper
                                    ref={swiperRef}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={20}
                                    pagination={{ clickable: true }}
                                    breakpoints={breakpoints}
                                    navigation={{
                                        prevEl: prevRef.current,
                                        nextEl: nextRef.current,
                                    }}
                                    loop={1}
                                >

                                    {posts.map((post) =>
                                        <SwiperSlide key={post.id} >
                                            <BlogsCarousel post={post} />
                                        </SwiperSlide>
                                    )}

                                </Swiper>
                            </div>
                        }

                    </div >

                </div>

            </div>

            <Footer />
        </>
    );
}

export default AboutUs;