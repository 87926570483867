import React from 'react'

function BlogNumberList({ blogList }) {
    return (
        <ol className='blog-body-item blog-number-list'>
            {blogList.map((list, idx) => (
                <li key={idx} className={`blog-number-list__item ${idx === blogList?.length - 1 ? 'no-margin' : ''}`}>
                    <span> {idx + 1}. </span>
                    <span className='blog-number-list__title'> {list.title} </span> :
                    <p className='blog-number-list__text'>{list.text} </p>
                </li>)
            )
            }
        </ol>
    )
}

export default BlogNumberList