import React from 'react'
import { b1Mob, b1Web } from '../../assets/images'

function SectionOne() {
    return (
        <div className="section-one" >
            <div className='section-one__img-bg app-img-border-rotate'>

                <img className='section-one__img hide-small ' src={b1Web} alt="landing-img-b1" />
                <img className='section-one__img hide-big' src={b1Mob} alt="landing-img-b1" />

            </div>
            <div className="section-one__container">
                <h1 className='section-one__title'>  Speed focused learning algorithm. </h1>
                <p className="section-one__sub-title">
                    Crack tough questions in {"<"} 2 minutes. Our
                    software makes you think and solve faster.
                </p>
            </div>
        </div>
    )
}

export default SectionOne