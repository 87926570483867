import React, { useState } from "react";
import { Link } from 'react-scroll';
import { useNavigate } from "react-router-dom";
import BackDrop from "../ui/backdrop/BackDrop";

import {
	menuIcon,
	closeIcon,
	usersIconURL,
	phoneIconURL,
} from "../../assets/icons";

import { nioLogo } from "../../assets/images";

function SimpleNavLinks(props) {
	const navigate = useNavigate();

	const { handleClickLogo, handleStartPracticingBtn } = props;

	const [isOpen, setIsopen] = useState(false);

	const ToggleSidebar = () => {
		setIsopen(!isOpen);
	};

	const handleNavClick = (destinationPath) => {
		navigate(destinationPath);
		setIsopen(false);
	};

	const handleSignIn = () => {
		navigate("/sign-in", { state: { defaultLogin: true } });
	};

	const scrollToContactForm = () => {
		navigate("/", {
			state: {
				isScrollToContactForm: true
			}
		})
	}

	return (
		<div className="header-container">
			<button
				className="header__left header__site-logo"
				onClick={handleClickLogo}
				name="btn-site-logo"
			>
				<img className="siteIcon" alt="nioclass" src={nioLogo} />
				<span className="header__part header__site-name">math</span>
			</button>

			<div className="header__right">
				<nav className="navbar__main">
					<button className="navbar_link" onClick={() => navigate("/about-us")}>
						<div>About Us</div>
					</button>

					<button
						className="navbar_link"
						onClick={() => navigate("/contact-us")}
					>
						<div>Contact</div>
					</button>

					<button className="app-btn-1 small"
						onClick={scrollToContactForm}>Contact for Demo
					</button>

					{/* {!(location.pathname === '/sign-in') 
					&& <button className="app-btn-1-reverse hide-small" 
					onClick={handleSignIn}>
							Log In
						</button>} */}
				</nav>

				<div className="header__menu-btn" onClick={ToggleSidebar}>
					☰
				</div>
			</div>

			<nav className={`navbar__slider ${isOpen ? "show" : ""} `}>
				<aside className="navbar__slider__menu-container">
					<div className="">
						<button className="navbar__slider__close" onClick={ToggleSidebar}>
							<img src={closeIcon} alt="close-btn" />
						</button>

						<div className="navbar__slider__menu-item">
							<img src={usersIconURL} />
							<button onClick={() => handleNavClick("/about-us")}> About Us</button>
						</div>

						<div className="navbar__slider__menu-item">
							<img src={phoneIconURL} />
							<button onClick={() => handleNavClick("/contact-us")}>
								Contact
							</button>
						</div>
					</div>

					{/* <div className="navbar__slider__btn-container">
												<button className="navbar__slider__menu-item log-out-btn" onClick={handleSignIn}>
														Log In
												</button>
										</div> */}
				</aside>
			</nav>

			{isOpen && <BackDrop closeHandler={() => setIsopen(false)} />}
		</div>
	);
}

export default SimpleNavLinks;
