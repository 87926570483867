
import './pre-loader.css';

const Preloader = () => {

    return (
        <>
            <div className="preloader">
                <img
                    src="https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/LogoNioclassAnimated.gif"
                    alt="nioclass Loading ... "
                    className="preloader__img"
                />
            </div>
        </>
    );
};
export default Preloader;
